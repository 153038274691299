.popup-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $pad;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  background: rgba(#000, 0.5);

  &.is-alignLeft {
    justify-content: left;
  }
}

%popup-body {
  max-height: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.5);
  border-radius: $rad-popup;
}
%popupView-body {
  min-width: 350px;
  max-width: 80%;
  overflow: auto;
}
.popup-body {
  @extend %popup-body;
  @extend %popupView-body;
}
.modal-popup-body {
  @extend %popup-body;
  overflow: hidden;
  width: 100%;
  //max-width: 1200px;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: $rad-popup;
}
.is-fullWidth {
  .modal-popup-body {
    max-width: none;
  }
}
.popup-top {
  color: $c1;
  padding: $pad-med 0;
  text-align: center;
  border-bottom: 2px solid $c-bg-grey-light;
  margin: 0 20px;
}
.popup-top--modal {
  min-height: 50px;
}
.popup-title {
  font-weight: $fw-semibold;
  font-size: 1.2rem;
  margin: 0;
  text-align: start;
}
.popup-main {
  overflow: auto;
  height: 100%;

  &.pad {
    padding: $pad-med $pad;
  }
}
.popup-bottom {
  border-top: 1px solid $c-border;
  padding: $pad-small $pad;
  display: flex;
  justify-content: center;
  .t-button {
    margin: $pad-small / 2;
    min-width: 90px;
  }
}
.popup-btn-cancel {
  background-color: #fff;
  border: 1px solid $c-border;
  color: $c-grey;
  font-weight: inherit;
  &:hover:not([disabled]) {
    color: rgba($c-grey, 0.7);
    background-color: #fff;
  }
}
.popup-overlayClose {
  cursor: pointer;
}
.popup-icon {
  font-size: 37px;
  &.fa-exclamation-triangle {
    color: $c-warn;
  }
  &.fa-check {
    color: $c1;
  }
  &.fa-question-circle,
  &.fa-trash {
    color: $c-item;
  }
}
.popup-close {
  @extend %f-awsome;
  color: inherit;
  font-size: 1.4em;
  &:after {
    content: "\f00d"; // times
  }
  &:hover {
    opacity: 0.8;
  }
}
.is-rtl .popup-icon {
  margin-left: $pad-small;
}
.is-ltr .popup-icon {
  margin-right: $pad-small;
}
.is-flexible {
  > .modal-popup-body,
  > .modal-popup-body > .popup-main-hold,
  > .modal-popup-body > .popup-main-hold > .popup-main {
    height: auto;
    width: auto;
    max-height: 100%;

    &.wide {
      width: 80vw !important;
    }
  }
  > .modal-popup-body > .popup-main-hold > .popup-main {
    overflow: visible;
  }
  > .modal-popup-body,
  > .modal-popup-body > .popup-main-hold {
    overflow: auto;
  }
  .ui-dropdown > .ui-dropdown-panel {
    position: static;
  }
}
.is-popupDisplay {
  .modal-popup-body {
    width: auto;
    height: auto;
    display: block;
    @extend %popupView-body;
  }
  .popup-main-hold {
    height: auto;
  }
  .popup-main,
  .popup-top {
    padding-left: 15px;
    padding-right: 15px;
  }
}
