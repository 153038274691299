.c1 {
  color: $c1;
}
.c2 {
  color: $c2;
}
body a {
  color: $c1;

  &.disabled {
    color: $c-grey-med;
    cursor: default;
    text-decoration: none;
  }

  &.icon {
    color: inherit;
  }
}
body a:hover {
  text-decoration: underline;
}
input,
button,
a,
select,
.ui-multiselect,
.ui-inputswitch,
.ui-inputswitch-slider,
textarea {
  &:focus {
    @extend %onfocus;
  }
}

hr,
.t-hr {
  width: 100%;
  min-width: 100%;
  color: $c-border;
  border: 1px solid $c-border;
  background-color: $c-border;
  margin: 0;
}

.t-hrY {
  height: 100%;
  margin: 0 running($pad-med);
  width: 1px;
  background-color: $c-border;
}
.l-flex > .t-hrY {
  height: auto;
}

.t-hrY--med {
  margin: 0 running($pad-small);
}
.is-focused {
  @extend %onfocus;
}
.is-focused-strong {
  box-shadow: 0px 0px 10px 6px rgba($c-searchSelect, 0.75);
}
tr.is-focused {
  position: relative;
  z-index: 9;
}

%no-margin-last-child {
  &:last-child {
    margin-bottom: 0;
  }
}
.pointer-arrow-holder {
  margin: 0 auto;
  width: 0;
  height: 0;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  overflow: visible;
}
.pointer-arrow-svgWrap {
  margin: auto;
  width: running($pad-med);
  height: running($pad-med);
  position: absolute;
  top: 0;
  left: -$pad;
  right: -$pad;
  svg {
    width: 100%;
    height: 100%;
  }
}
.t-ltr {
  direction: ltr;
  text-align: left;
}
.t-u {
  text-decoration: underline;
}
.t-del {
  text-decoration: line-through;
}
%t-widget {
  //margin-bottom: $pad-med;
  padding: 20px 20px 10px;
  //border: 1px solid $c-border;
  background-color: #fff;
  border-radius: $rad1;
}
.t-widget {
  @extend %t-widget;
}
.t-widget--sharp {
  @extend %t-widget;
  border-radius: 0;
}
.t-widget--stick {
  @extend %t-widget;
  margin-bottom: -1px;
  border: none;
  border-radius: $rad $rad 0 0;
}
.t-widget--noBottomPad {
  @extend %t-widget;
  padding-bottom: 0;
}
.t-widget--noTopPad {
  @extend %t-widget;
  padding-top: 0;
}
.t-widget--noPadding {
  @extend %t-widget;
  padding: 0 !important;
}
.t-pad--med {
  padding: running($pad-med / 2);
}
.t-pad--small {
  padding: running($pad-small / 2);
}

.t-widgetTitle {
  margin: 0; // 0 0 0.2em;
  font-weight: $fw-semibold;
  color: $c2-dark;
  font-size: 1.1rem;
  padding-bottom: 15px;
}
.t-widgetAccentTitle {
  margin: 0;
  font-weight: $fw-bold;
  color: white;
  background-color: $c1;
  font-size: 1.1em;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 20px 40px;
}

h3.t-widgetTitle {
  font-size: running(19);
}

.t-inputWrap {
  min-width: running(120px);
  flex-grow: 1;

  &.is-short {
    min-width: running(40px);
  }
}

.t-inputWrap-spread- {
  padding: 0;
  height: 100%;
  &.t-panel-cell {
    padding: 0;
  }
  .t-inputWrap-in {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  textarea.t-input {
    flex-grow: 1;
    //height: 100%;
    min-height: 100%;
    margin-top: 0.3em;
  }
}
.t-inputWrap-table {
  flex-grow: 1;
  > *:first-child {
    flex-grow: 1;
  }
}
.t-inputWrap-in {
  position: relative;
  &.is-loading {
    &:after {
      @extend %spread;
      content: "";
      //background: rgba(#fff, 0.7);
      z-index: 22;
    }
  }
  .t-inputWrap-loader {
    position: absolute;
  }
  &.is-singleLine {
    display: flex;
    align-items: center;
    margin-top: 8px;

    > .t-inputWrap-table {
      flex-grow: 1;
      width: 0;
    }
    .t-inputWrap-header {
      margin: 0;
      .is-rtl & {
        margin-left: running($pad-small);
      }
      .is-ltr & {
        margin-right: running($pad-small);
      }
    }
    .t-buttonSet-child {
      width: auto;
    }

    &.is-label-after {
      flex-direction: row-reverse;
      justify-content: flex-end;

      > .t-inputWrap-table {
        flex-grow: 0;
        width: auto;
      }

      .t-inputWrap-header {
        margin: 0;
        .is-ltr & {
          margin-left: running($pad-small);
        }
        .is-rtl & {
          margin-right: running($pad-small);
        }
      }
    }

    h4 {
      margin-bottom: 0;
    }
  }
}
$loader-width: 40%;
.t-inputWrap-loader {
  width: 100%;
  height: 5px;
  position: relative;
  bottom: 0;
  z-index: 23;
  overflow: hidden;
  &:after {
    content: "";
    width: $loader-width;
    height: 100%;
    position: absolute;
    background-color: $c1;
    animation-name: moveLeft;
    animation-duration: 500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  .is-rtl & {
    left: 0;
    &:after {
      left: -$loader-width;
    }
  }
  .is-ltr & {
    right: 0;
    &:after {
      right: -$loader-width;
    }
  }
}
.t-input-search-wrap {
  position: relative;
  background-color: transparent;
  .t-input {
    position: relative;
    z-index: 1;
    background-color: white;
    .is-rtl & {
      padding-left: running($input-height);
    }
    .is-ltr & {
      padding-right: running($input-height);
    }
  }
  &:after {
    content: "\f002"; // search
    @extend %f-awsome;
    display: flex;
    justify-content: center;
    width: running($input-height);
    height: 100%;
    align-items: center;
    position: absolute;
    top: 0;
    z-index: 1;
    color: $c-border;
    .is-rtl & {
      left: 0;
    }
    .is-ltr & {
      right: 0;
    }
  }
}

.t-input {
  display: block;
  flex-grow: 1;
  padding: 0.375rem 0.5rem;
  border: 1px solid $c-border;
  width: 100%;
  height: $input-height;
  font-family: inherit;
  font-size: running(14px);
  font-weight: $fw-light;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  color: $c-grey;
  border-radius: $rad-input;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  @include placeholder($c-grey-med);
  &:focus {
    @include placeholder(transparent);
    outline: none;
  }
  &[readonly] {
    opacity: 0.7;
    cursor: default;
    &:focus {
      box-shadow: none;
    }
  }

  &[disabled] {
    background-color: #f8f9fa;
  }

  &.t-input--time {
    display: flex;
  }

  &.t-input--colored {
    background-color: $c-body;
    font-weight: $fw-regular;
  }

  &.t-input-icon-search {
    background: url("../../assets/img/Search_grey.svg");
    background-repeat: no-repeat;
    background-color: white;
    background-position: 12px 7px;
    background-size: 20px 20px;
  }
}
.t-input--time {
  input {
    width: 2.1em;
  }
}
.is-file {
  position: relative;
  .t-input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 2;
    cursor: pointer;
  }
  &:hover {
    .t-chooseFile-label {
      background: lighten($c1, 10);
    }
  }
}
.t-chooseFile {
  display: flex;
  height: running($input-height);
  border: 1px solid $c-border;
  width: 100%;
  border-radius: running($rad/2);
  overflow: hidden;
}
.t-chooseFile-label,
.t-chooseFile-value {
  display: flex;
  align-items: center;
  padding: running(0) running($pad-med);
  cursor: pointer;
  white-space: nowrap;
}
.t-chooseFile-label {
  color: #fff;
  background: $c1;
}
.t-chooseFile-value {
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
}

textarea.t-input {
  min-height: 7.5em; // running(80px);
  resize: vertical;
}
.t-hover-simple {
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}
.t-hover-tr {
  cursor: pointer;
  &:hover {
    td {
      background-color: $c1-light !important;
    }
  }
}
.t-recipient-row {
  padding-bottom: $pad-small;
  border-bottom: 1px solid $c-border;
  border-radius: $rad;
}

.t-recipientListHold {
  .t-addRemove {
    display: flex;
    flex-wrap: wrap;
    margin: -0.25em;
  }
  .t-addRemove-item {
    display: flex;
    margin: 0.25em;
    border: 1px solid $c-border;
    padding: running($pad-small);
    border-radius: $rad;
    .cell-100 {
      width: auto;
    }
    .ui-cell {
      width: auto;
      padding: 0;
    }
    > span {
      padding: 0;
      height: auto;
      border: none;
      display: block;
      .is-rtl & {
        padding-left: 0.2em;
      }
      .is-rtl & {
        padding-right: 0.2em;
      }
      &:last-of-type {
        padding: 0;
      }
    }
  }
}
.layout-table > loader .t-inputWrap-loader,
.login-container > loader .t-inputWrap-loader {
  position: fixed;
}
.t-table-showMore {
  border: 3px solid $c-more-border;
  /*background: linear-gradient(
    to bottom,
    #eee 0%,
    lighten(#eee, 3) 38%,
    lighten(#eee, 3) 95%,
    #eee 100%
  );*/
}
@keyframes moveLeft {
  from {
    left: -$loader-width;
  }
  to {
    left: 100 + $loader-width;
  }
}

label.t-checkbox {
  margin-bottom: 0;
}
@mixin buttonSet($pad) {
  margin-bottom: -$pad-small / 2;
  > .t-button,
  > button,
  > .t-buttonSet-unit,
  > checkbox-wrap,
  .t-buttonSet-child {
    margin-bottom: $pad-small / 2;
    .is-rtl & {
      margin-left: $pad-small / 2;
      &:last-child {
        margin-left: 0;
      }
    }
    .is-ltr & {
      margin-right: $pad-small / 2;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .t-actionButton {
    margin-bottom: $pad / 2;
  }
}
.t-buttonSet {
  @include buttonSet($pad-small);
}
.t-buttonSet--med {
  @include buttonSet($pad-med);
}
.t-buttonSet--small {
  @include buttonSet($pad-small);
}

.t-buttonSet-child {
  display: inline-block;
}

$button-shadow1: inset 0px -0.2em 2px 0px rgba(0, 0, 0, 0.2);
$button-shadow1-active: inset 0px 0.2em 2px 0px rgba(0, 0, 0, 0.2);
$button-shadow2: inset 0px 0.2em 7px 0px rgba(255, 255, 255, 0.2);
$button-shadow2-strong: inset 0px 0.4em 9px 0px rgba(255, 255, 255, 0.3);
.t-button,
%t-button {
  padding: 0.7em 1.4em;
  min-height: 2em; //em($fs-btn, $input-height);
  font-size: $fs-btn;
  background-color: $c1;
  border-radius: $rad-btn;
  color: white;
  //box-shadow: $button-shadow1, $button-shadow2;
  &:hover:not([disabled]) {
    filter: brightness(90%);
  }
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.7;
    > .fa,
    > .fas,
    > .far {
      transform: translateY(0.02em);
    }
  }
  &:active {
    //box-shadow: $shadow-focus !important;
  }
  &:focus,
  &.is-focused {
    //box-shadow: $button-shadow1, $button-shadow2, $shadow-focus;
    box-shadow: none;
  }
  &.is-active {
    filter: brightness(110%);
    text-shadow: 0 0 2px inherit;
    //box-shadow: $button-shadow1-active, $button-shadow2;
    &:focus {
      //box-shadow: $button-shadow1-active, $button-shadow2, $shadow-focus;
    }
  }

  &.t-button-warn {
    background-color: $c-reject;
  }
}
tbody:focus {
  box-shadow: 0px 0px 6px 0px rgba($c1, 0.45);
}
.t-button--fat {
  @extend %t-button;
  $fs: $fs-btn * 1.5;
  height: em($fs, $input-height * 1.6);
  font-size: running($fs);
  border-radius: $rad * 2;
  box-shadow: none;
}

.t-button--pill {
  @extend %t-button;
  @extend %l-align-center;
  width: em(13, 25px);
  height: em(13, 25px);
  padding: 0 !important;
  border-radius: 50%;
  min-height: 0;
  font-size: 13px;
}
.l-ta-center .t-button--pill {
  margin: auto;
}
.t-button--rounded {
  border-radius: 3000px;
  padding: 0.2em;
}
.t-button--icon {
  border-radius: 50%;
  height: 36px;
  width: 36px;
  padding: 8px;
  margin: 0 10px;
}
.t-button--big {
  font-size: 2em;
  padding: 0.2em 1em 0.44em;
}
.t-button--med {
  font-size: 1em;
  padding: 0.3em 1em 0.44em;
  height: auto;
  min-height: 0;
}
.t-button--small {
  font-size: 0.8em;
  padding: 0.3em 1em 0.44em;
  min-height: 0;
}
.t-button--flat {
  box-shadow: none;
}
.t-button--select {
  border: 1px solid $c-grey-light;
  border-radius: 0;
}
.t-actionButton {
  display: block;
}

.t-button-outline {
  @extend %t-button;
  background-color: transparent;
  color: $c1;
  border: 2px solid $c1;
  box-shadow: none;
  padding: 0.6em 1.4em;

  &.inverted {
    color: white;
    background-color: $c1;
  }

  &.t-button-warn {
    background-color: transparent;
    border-color: $c-reject;
    color: $c-reject;
  }
  
  &.loading {
    position: relative;
    color: $c-grey-accent;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20px;
      height: 20px;
      margin-top: -12px;
      margin-left: -12px;
      border-radius: 50%;
      border: 3px solid $c-searchFocus;
      border-top-color: $c-body;
      animation: spinner .8s linear infinite;
    }
  }
}

@keyframes spinner {
  to {transform: rotate(360deg);}
}

.t-border {
  border: 1px solid $c-border;
}
.t-border.t-border--ltrLeft,
.t-border.t-border--ltrRight {
  border: none;
}
.is-ltr .t-border.t-border--ltrLeft {
  border-left: 1px solid $c-border;
}
.is-rtl .t-border.t-border--ltrLeft {
  border-right: 1px solid $c-border;
}
.is-ltr .t-border.t-border--ltrRight {
  border-right: 1px solid $c-border;
}
.is-rtl .t-border.t-border--ltrRight {
  border-left: 1px solid $c-border;
}

.t-actionButton-table {
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: em($fs-btn, 200px);
  min-height: em($fs-btn, 40px);
  max-width: 100%;
  text-align: initial;
}
.t-actionButton-icon {
  width: em($fs-btn, 30);
}
.is-rtl .t-actionButton-text {
  margin-right: $pad-small / 2;
}
.is-ltr .t-actionButton-text {
  margin-left: $pad-small / 2;
}
.t-btn-more {
  border: 1px solid $c-grey-light;
  i {
    transition: transform 400ms ease-out;
    position: relative;
    top: -0.01em;
  }
  &.is-open fa-icon {
    .is-rtl & {
      transform: rotate(-90deg);
    }
    .is-ltr & {
      transform: rotate(90deg);
    }
  }
}
.t-tr-arrow {
  .is-rtl & {
    transform: rotate(-180deg);
  }
}
.t-radio {
  width: running(20px);
  height: running(20px);
  border-radius: 50%;
  position: relative;
  border: 1px solid $c-border;
  background-color: #fff;
  @extend %l-align-center;
  &:hover {
    color: $c1;
  }
}

%btn-icon {
  @extend %f-awsome;
  display: inline-block;
  font-size: 80%;
}
.btn-icon-add {
  &:before {
    @extend %btn-icon;
    content: "\f067";
  }
}
.btn-icon-forgotPassword {
  &:before {
    @extend %btn-icon;
    display: inline-block;
    vertical-align: middle;
    font-size: 80%;
    content: "\f084";
  }
}
.btn-order-search {
  height: $input-height;
  font-size: runner($fs-btn);
  min-width: em($fs-btn, 180px);
}
.btn-warn {
  background-color: $c-warn;
  &:before {
    @extend %btn-icon;
    content: "\f06a"; // exclamation-circle;
  }
  &:hover:not([disabled]) {
    background-color: darken($c-warn, 10);
  }
}
.btn-viewUsers {
  background: $c1;
  color: #fff;
  border-radius: 50%;
  width: 1.8em;
  height: 1.8em;
  &:after {
    @extend %f-awsome;
    content: "\f2c2";
  }
}
.btn-editUser {
  color: $c1;
  &:after {
    font-size: 1.1em;
    @extend %f-awsome;
    content: "\f303"; // user-edit
  }
}
%btn-symbol {
  display: inline-block;
  .is-rtl & {
    margin-left: 0.2em;
  }
  .ir-ltr & {
    margin-right: 0.2em;
  }
}
.t-button-search {
  @extend %t-button;

  color: $c-grey-med;
  font-weight: 200;
  background-color: white;
  border: 1px solid $c-quote-border;

  /*&:after {
    @extend %f-awsome;
    @extend %btn-symbol;
    content: "\f002";
  }*/
}
.t-button-cancel {
  @extend %t-button;
  background-color: $c-grey;
  font-size: 16px;
  &:before {
    @extend %f-awsome;
    @extend %btn-symbol;
    content: "\f05e";
  }
  &:hover:not([disabled]) {
    background-color: lighten($c-grey, 3);
  }
}
.t-button-delete {
  @extend %t-button;
  background-color: $c-grey;
  &:before {
    @extend %f-awsome;
    @extend %btn-symbol;
    content: "\f1f8"; // trash
  }
  &:hover:not([disabled]) {
    background-color: $c-warn;
  }
}
.t-button-add {
  @extend %t-button;
  font-size: 16px;
  &:before {
    @extend %f-awsome;
    @extend %btn-symbol;
    content: "\f067";
  }
}
.t-button-approve {
  @extend %t-button;
  font-size: 16px;
  &:before {
    @extend %f-awsome;
    @extend %btn-symbol;
    content: "\f00c"; //check
  }
}

%t-button-close {
  color: $c-grey;

  &:before {
    @extend %f-awsome;
    @extend %btn-symbol;
    content: "\f00d"; // times
  }
  &:hover {
    opacity: 0.8;
  }
  &:focus {
    box-shadow: $shadow-focus;
  }
}
.t-button-close {
  @extend %t-button-close;
  width: running($pad);
  height: running($pad);
  border-radius: 50%;
}
.t-button-clearSearch {
  @extend %t-button-close;
  font-size: 14px;
  border-bottom: 1px solid;
  opacity: 0.8;
  &:hover {
    border-color: transparent;
  }
}

.t-title--small {
  font-size: running(16px);
  margin: 0 0 em(16, $pad-small);
  font-weight: $fw-bold;
  &:only-child {
    margin-bottom: 0;
  }
}
.t-label {
  font-size: 12px;
  font-weight: $fw-bold;
}

.t-panel {
  // margin: -$pad-med / 2; //maria
}
.t-panel-minHeight {
  min-height: running(200);
}
%t-panel-cell {
  > data-box {
    display: block;
    height: 100%;
  }
}
.t-panel-cell {
  @extend %t-panel-cell;
  padding: 5px; // running($pad-med / 2);

  .no-indent & {
    padding: 5px 0;
  }
}

.t-panel-small {
  margin: -$pad-small / 2;
}
.t-panel-cell-small {
  @extend %t-panel-cell;
  padding: $pad-small / 2;
}
%spacer {
  flex-shrink: 1;
}
.t-space {
  height: $pad;
  min-width: $pad;
  @extend %spacer;
}
.t-space--med {
  height: running($pad-med);
  min-width: running($pad-med);
  @extend %spacer;
}
.t-space--small {
  height: running($pad-small);
  min-width: running($pad-small);
  @extend %spacer;
}
.t-space--smallest {
  height: 0.25em;
  min-width: 0.25em;
  @extend %spacer;
}

.t-alert,
%t-alert {
  border: 1px solid;
  border-radius: $rad;
  padding: running($pad-small) running($pad-med);
  margin: 20px 0;
}
.t-alert-info {
  @extend %t-alert;
  background-color: white;
  border-color: white;
}
.t-alert-warn {
  @extend %t-alert;
  background-color: white;
  color: $c-warn;
  border-color: $c-warn;
}
.t-alert-grey {
  @extend %t-alert;
  background-color: $c-grey-light;
  border-color: lighten($c-grey-light, 10);
}
.t-datalist {
  dd,
  dt {
    margin-bottom: 3px;
  }
  dd {
    color: $c1;
  }
}
.t-dataBox-figure {
  width: 33.3333%;
  max-width: running(250);
  img {
    max-width: 100%;
  }
}
checkbox-wrap {
  display: inline-block;
}
.t-checkbox {
  position: relative;
  input[type="checkbox"],
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    top: 0;
  }
  input[type="checkbox"]:checked {
    + .t-checkbox-appearance {
      &:after {
        opacity: 1;
      }
    }
  }
  input[type="checkbox"]:disabled {
    + .t-checkbox-appearance {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  input:focus {
    + .t-checkbox-appearance {
      @extend %onfocus;
    }
  }
  input[type="radio"]:checked,
  input.is-asRadio:checked {
    + .t-checkbox-appearance {
      background: radial-gradient(ellipse at center, $c1 50%, #ffffff 50%);
    }
  }
  input.is-asRadio + .t-checkbox-appearance,
  input[type="radio"] + .t-checkbox-appearance {
    border-radius: 50%;
    &:after {
      display: none;
    }
  }
}
.t-checkbox-appearance {
  position: relative;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  border: 1px solid $c-border;
  color: $c1;
  background-color: $c-body;
  &:after {
    @extend %l-align-center;
    @extend %spread;
    content: "\f00c";
    font-size: 0.65em;
    opacity: 0;
    position: relative;
    top: -0.01em;
  }
  .is-rtl & {
    margin-left: 5px;
  }
  .is-ltr & {
    margin-right: 5px;
  }

  .dirty & {
    border-color: red;
  }
}

.order-details-table {
  margin-top: 0;
  margin-bottom: 0;
  .t-datalist {
    padding-top: 0;
    padding-bottom: 0;
    &:last-child {
      border: none !important;
    }
    .is-rtl & {
      border-left: 1px solid $c-border;
    }
    .is-ltr & {
      border-right: 1px solid $c-border;
    }
  }
}
.t-code {
  background: $c-body;
  padding: running($pad-small);
  border: 1px solid $c-border;
  border-radius: $rad;
  max-height: 200px;
  direction: ltr;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.rotate-animation,
.t-spin {
  animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.fa-spinner {
  color: $c1;
}

.is-invalid {
  .t-input {
    border-color: $c-warn;
  }
}
.is-hidden {
  display: none !important;
}
.t-invalid-text {
  font-size: 12px;
  color: $c-warn;
  padding-top: $pad-small;
}
.t-tooltip {
  @extend %ff-open-sans;
  overflow: auto;
  max-width: 210px;
  max-height: 200px;
  color: #000;
  padding: 4px 6px;
  border: 1px solid $c-border;
  border-radius: 4px;
  background: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: $fw-light;
  line-height: 1.3em;
}

.check-mark {
  display: inline-block;
  vertical-align: middle;
}

.t-check-mark-true {
  color: $c1;
}
.t-check-mark-false {
  color: $c-grey;
}
.t-panel.item-desc {
  font-size: 14px;
}
.t-caret {
  &:after {
    .is-rtl & {
      content: "\f137"; //chevron-circle-left
    }
    .is-ltr & {
      content: "\f138"; //chevron-circle-right
    }
  }
}

.t-key-value-pair {
  padding-top: 0;
  padding-bottom: 0;
  break-inside: avoid;
}
.t-key-value-pair-box {
  width: 100%;
  height: 100%;
  align-items: center;
  padding: running($pad-small) 0;
  border-bottom: 1px solid $c-border;
  font-size: 14px;
  dt {
    font-weight: $fw-semibold;
    width: 66%;
    flex-shrink: 1;
    .is-rtl & {
      margin-left: running($pad-small) / 2;
    }
    .is-ltr & {
      margin-right: running($pad-small) / 2;
    }
  }
  dd {
    flex-grow: 1;
    text-align: end;
  }
}

.t-filtered-list {
  padding-top: running($pad-small + $input-height + $pad-small);
}
.t-filtered-list-filter {
  position: absolute;
  left: 0;
  right: 0;
  width: calc(100% - 30px);
  margin: 0 auto;
  background: #fff;
  /* width: 100%; */
  z-index: 2;
  padding: 10px 15px 10px;
  height: running($pad-small + $input-height + $pad-small);
  bottom: calc(100% - #{running($pad-small + $input-height + $pad-small)});
}
.search-input-wrap {
  display: block;
  position: relative;
  > .t-input {
    background-color: transparent;
    position: relative;
    z-index: 2;
    .is-rtl & {
      padding-left: running(40px);
    }
    .is-ltr & {
      padding-right: running(40px);
    }
  }
  &:after {
    @extend %f-awsome;
    content: "\f002"; //search
    text-align: center;
    position: absolute;
    height: 100%;
    display: flex !important;
    align-items: center;
    justify-content: center;
    top: 0;
    width: running(40px);
    line-height: 2.5em;
    .is-rtl & {
      left: 0;
    }
    .is-ltr & {
      right: 0;
    }
  }
}
.t-breadcrumbs {
  padding: running($pad-small) 0;
  color: $c-grey-med;
  li {
    &:after {
      @extend %f-awsome;
      display: inline-block;
      margin: 0 0.5em;
      position: relative;
      bottom: 0.17em;
      font-size: 59%;
      .is-rtl & {
        content: "\f053"; //chevron-left
      }
      .is-ltr & {
        content: "\f054"; //chevron-right
      }
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
  }
}

// directives
[slide-x] {
  transition: height 400ms linear;
  will-change: height;
}

.t-widget-collapse {
  border: 1px solid $c-border;
  margin-bottom: -1px;
}
.t-widget-collapse-title {
  margin: 0;
}
.widget-collapse-image-small {
  width: running(60px);
  .is-rtl & {
    margin-left: $pad-small;
  }
  .is-ltr & {
    margin-right: $pad-small;
  }
}
.t-widget-collapse-button {
  width: 100%;
  color: inherit;
  text-align: initial;
  padding: running($pad-small) running($pad-med);
  position: relative;

  .is-rtl & {
    padding-left: running(50px);
  }
  .is-ltr & {
    padding-right: running(50px);
  }
  &:before {
    content: "";
    position: absolute;
    background-color: $c-border;
    opacity: 0.8;
    height: 70%;
    width: 1px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    $pos: running(40px);
    .is-rtl & {
      left: $pos;
    }
    .is-ltr & {
      right: $pos;
    }
  }
  &:after {
    @extend %f-awsome;
    width: em(22, 40px);
    line-height: em(22, 30px) !important;
    height: em(22, 30px);
    font-size: running(22px);
    position: absolute;
    text-align: center;
    top: 0;
    bottom: 0;
    margin: auto 0;
    transition: transform 200ms ease-out;
    color: $c-grey-d;
    .is-rtl & {
      content: "\f104"; //angle-left
      left: 0;
    }
    .is-ltr & {
      content: "\f105"; //angle-right
      right: 0;
    }
  }
  &:hover {
    background: lighten($c-border, 13);
  }
}
.is-open {
  .t-widget-collapse-button {
    background: lighten($c-border, 13);
    &:after {
      transform: rotate(90deg);
    }
  }
}
.t-widget-collapse-content {
  color: $c-dark;
  margin: 0;
  border-radius: 0;
  border: none;
}
.t-addRemove {
  @extend %norm-ul;
  display: table;
  border-collapse: collapse;
  width: 100%;
}
.t-addRemove-item {
  display: table-row;
  > span {
    display: table-cell;
    vertical-align: middle;
    height: $input-height;
    border-bottom: 1px solid $c-border;
  }
  .ui-cell {
    padding: running($pad-small);
    width: $input-height;
  }
  &.is-hoverable,
  &[role="button"] {
    cursor: pointer;
    &:hover {
      background-color: $c-info;
    }
  }
  &:focus {
    outline: 1px solid $c1;
  }
  .cell-fit {
    width: 1px;
  }
}
.btn-unselect {
  &:before {
    @extend %f-awsome;
    @extend %btn-symbol;
    content: "\f00d"; // times
  }
  &:hover {
    color: $c-warn;
  }
}
.clItem-storeId {
  width: 60px;
}
//icons
.icon-sentBy:before {
  content: "\f1d8"; // paper-plane
}
.icon-openedBy:before {
  content: "\f2b6"; // envelope-open
}
.icon-createdBy:before {
  content: "\f067"; // plus
}
.t-list-item {
  padding: running($pad-small) 0;
  border-bottom: 1px solid $c-border;
}
%frame {
  position: relative;
  overflow: hidden;
  height: 0;
  img {
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    top: -99px;
    bottom: -99px;
    left: -99px;
    right: -99px;
    margin: auto;
  }
}
.t-frame--100 {
  @extend %frame;
  padding-top: 100%;
}
.item-display-frame {
  @media (max-width: 1300px) {
    max-width: 35%;
  }
}
.t-tablist {
  //background-color: #fff;
  //border-radius: 26px;
  //border: 1px solid $c-border;
  .has-topStroke & {
    border-bottom: 1px solid $c-border;
    > .t-tab {
      border: 1px solid $c-border;
      border-bottom: none;
    }
  }
}

.t-tab {
  font-weight: $fw-regular;
  font-size: 1rem;
  color: #6c757d; //$c2-dark;
  padding-bottom: 0.7em;

  .tabs-small & {
    font-size: 1rem;
  }

  &:hover {
    border-bottom: 2px solid #6c757d;
    text-decoration: none;
  }

  &.is-active {
    color: $c1;
    border-bottom: 2px solid $c1;
    font-weight: $fw-semibold;
    &:hover {
      color: $c1;
    }
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.tab-icon {
  margin-right: 0.5rem;
}

.tab-button-wrapper {
  &::after {
    content: "";
    margin: 0 1rem;
  }

  &:last-child {
    &::after {
      display: none;
    }
  }
}

.t-paddedWidget {
  padding: running($pad-med) running($pad-med);
}
.t-verticalPaddedWidget {
  padding: running($pad-med) 0;
}

.t-tabContainer {
  padding: 0 running($pad-med);
  .t-tabContainer {
    padding: 0;
  }
}
.t-preventScroll {
  min-height: 500px;
}
.t-preventScroll > .t-tabContainer,
.t-preventScroll > .t-tabContainer > tab-single > .t-tabPanel {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.t-block-search {
  @extend %flex;
  border-radius: $rad;
  border: 1px solid $c-border;
  overflow: hidden;
  .t-button,
  .t-input {
    border-radius: 0;
  }
  .t-input {
    @extend %l-cell-spread;
    width: 0;
    border: none;
  }
}

.t-autoComplete-panel {
  background: #fff;
  position: relative;
  z-index: 20;
  overflow: auto;
  max-height: running(300);
  border: 1px solid $c-border;
  border-radius: 0 0 $rad $rad;
  box-shadow: $shadow-focus;
}
.t-autoComplete-item {
  padding: running($pad-small) running($pad-small);
  border: 1px solid transparent;
  border-bottom-color: $c-border;
  cursor: pointer;
  position: relative;
  background-color: #fff;
  &.is-focused {
    color: $c-searchFocus;
    box-shadow: inset 0px -5px 13px 2px rgba(100, 100, 100, 0.2);
    text-shadow: 1px 1px 2px rgba(150, 150, 150, 1);
  }
  &:hover {
    color: $c-searchFocus;
  }
  &.is-selected:not(.is-focused) {
    border-bottom-color: $c-searchSelect;
    color: $c-searchSelect;
    font-weight: $fw-bold;
  }
}
.t-menuPanel {
  background: $c-border;
}

// TREE
.t-tree {
  @extend %norm-ul;
  h3,
  h4 {
    margin: 0 0 running($pad-small);
  }
  h3 {
    margin: 0;
    padding: em($fs-large, $pad-small) em($fs-large, $pad-med);
    font-size: running($fs-large);
    font-weight: 400;
    background-color: $c1-muted;
    color: #fff;
  }
  h4 {
    margin: 0;
    position: relative;
    font-size: running($fs-btn);
    color: $c1;
    font-weight: 100;
    &:after {
      content: "";
      border-bottom: 1px solid $c-border;
      width: em($fs-btn, $pad-med * 0.8);
      height: 50%;
      position: absolute;
      top: 0;
      .is-rtl & {
        right: em($fs-btn, -$pad-med);
      }
      .is-ltr & {
        left: em($fs-btn, -$pad-med);
      }
    }
  }
}

.is-rtl .t-tree-item .t-tree-item {
  padding-right: running($pad-med);
  border-right: 1px dotted $c-border;
}
.is-ltr .t-tree-item .t-tree-item {
  padding-left: running($pad-med);
  border-left: 1px dotted $c-border;
}
.t-notificationCounter {
  position: relative;
  display: inline-block;
  min-width: running(20px);
  height: running(20px);
  padding: running(2);
  background-color: $c-warn;
  border-radius: 50%;
  top: 0;
  left: 0;
  color: #fff;
  line-height: 1.355;
}
.input-wrap-hasFileSpread {
  .t-inputWrap-header,
  .t-chooseFile {
    display: none;
  }
}
.t-fileInput-spread-wrap {
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  &:hover .t-button {
    background-color: lighten($c2, 10);
  }
}
.t-fileInput-spread {
  @extend %spread;
  opacity: 0;
  cursor: pointer;
}
.t-pagination {
  display: flex;
  justify-content: center;
  > .t-pagiUnit {
    .is-ltr & {
      transform: rotate(180deg);
    }
  }
}
.t-pagiUnit {
  @extend %l-align-center;
  min-width: $pad;
  height: $pad;
  width: $pad;
  border: 1px solid transparent;
  flex-shrink: 0;
  border-radius: 50%;
  box-shadow: border-box;
  &.is-active {
    border-color: $c1;
  }
  &.is-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
.t-pagination-slider {
  overflow: hidden;
}
.t-pagination-slider-track {
  display: flex;
  transition: transform 400ms ease-in-out;
  .t-pagiUnit:only-child {
    visibility: hidden;
  }
}
.is-sliderCancelded {
  > .t-pagiUnit {
    display: none;
  }
}
.t-table-stripped {
  td,
  th {
    &:not([colspan]):nth-child(even) {
      background-color: $c-grey-light !important;
    }
  }
}
.t-clickableTh-icon {
  @extend %l-align-center;
  width: running($pad-med);
  height: running($pad-med);
  transition: all 400ms ease-out;
  border-radius: 50%;
}
.supplyCalendarList-table,
.monitorCustData-table {
  .t-clickableTh {
    .t-clickableTh-icon {
      display: none;
    }
    &.is-active {
      .t-clickableTh-icon {
        display: flex;
      }
    }
  }
}
.is-descendingOrder {
  .t-clickableTh-icon {
    transform: rotate(180deg);
  }
}
.t-subMenu {
  @extend %onfocus;
  position: absolute;
  top: 100%;
  z-index: 980;
  width: 100%;
  min-width: running(180);
  visibility: hidden;
  .is-rtl & {
    right: 0;
  }
  .is-ltr & {
    left: 0;
  }
  &.is-active {
    visibility: visible;
  }
  .t-tab {
    width: 100%;
    border: none;
    border-bottom: 1px solid $c-border;
    .l-align-x {
      justify-content: unset;
    }
  }
}

// campaigns
.promoFlow-next {
  color: $c1;
  transition: color 800ms ease-out;
  &[disabled] {
    color: $c-grey-light;
    cursor: not-allowed;
    .fas {
      text-shadow: 0 0 0 rgba(0, 0, 0, 0);
    }
  }
}
.promoFlow-next-arrowIcon {
  font-size: running(80);
  text-shadow: 5px 7px 12px rgba(0, 0, 0, 0.3);
  transition: text-shadow 800ms ease-out;
  &:before {
    .is-rtl & {
      // arrow left
      content: "\f060";
    }
    .is-ltr & {
      // arrow right
      content: "\f061";
    }
  }
}

.promoFlow-prev {
  $fs: 13;
  font-size: running($fs);
  white-space: nowrap;
  color: darken($c-grey-med, 15);
  padding: em($fs, $pad-small / 2) em($fs, $pad-small);
  border: 1px solid;
  border-radius: em($fs, $rad / 2);
  &:hover {
    color: $c-grey;
  }
}

.note {
  color: $c-success;
  font-weight: $fw-regular;
  font-size: 0.9em;
}

.uppercase {
  text-transform: uppercase;
}

.fa-stack {
  height: 1.5em;
  line-height: 1.5em;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
input:-internal-autofill-selected {
  background-color: $c-body !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px $c-body inset; /* Change the color to your own background color */
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px $c-body inset; /*your box-shadow*/
}

/* Scrollbar */

body .ui-dropdown,
body .ui-multiselect,
body chat,
body .t-scrollbar {
  ::-webkit-scrollbar {
    width: 8px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    -webkit-border-radius: 26px;
    border-radius: 26px;
    background: $c1-light;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: $c1;
  }
  ::-webkit-scrollbar-thumb:window-inactive {
    background: $c1;
  }
}

body .ui-dropdown,
body .ui-multiselect {
  /* Track */
  ::-webkit-scrollbar-track {
    margin-bottom: 26px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

// ~~~~~ OVERWRITES ~~~~ keep in end of file
.t-row {
  margin-bottom: running($pad);
}
.t-row--med {
  margin-bottom: running($pad-med);
  > .t-widgetTitle {
    margin-bottom: 0;
  }
}
.t-row--small {
  margin-bottom: running($pad-small);
  > .t-widgetTitle {
    margin-bottom: 0;
  }
}
.t-row--smalllest {
  margin-bottom: running($pad-small / 2);
}
.t-row--0 {
  margin-bottom: 0;
}
.t-list-row {
  width: 100%;
  display: flex;
  align-items: center;
  padding: running($pad-small) 0;
  border-bottom: 1px solid $c-border;
  border-top: 1px solid $c-border;
  margin-bottom: -1px;
}
.t-mar-top {
  margin-top: running($pad);
}
.t-pad-top {
  padding-top: running($pad);
}
.t-pad-top--med {
  padding-top: running($pad-med);
}
.t-pad-top--small {
  padding-top: running($pad-small);
}

.t-pad-bottom {
  padding-bottom: running($pad);
}
.t-pad-bottom--med {
  padding-bottom: running($pad-med);
}
.t-pad-bottom--small {
  padding-bottom: running($pad-small);
}
.t-pad-0 {
  padding: 0;
}

button,
a {
  &.t-list-row:hover {
    opacity: 0.8;
  }
}
.t-helper-pointer {
  position: absolute;
  overflow: visible;
  max-width: running(400);
  min-width: running(130);
  top: 0;
  z-index: 3;
  .is-rtl & {
    right: 100%;
    padding-right: running($pad-small);
  }
  .is-ltr & {
    left: 100%;
    padding-left: running($pad-small);
  }
}
.t-helper-box {
  @extend %t-widget;
  border-color: $c-info;
  position: relative;
  font-size: 12px;
  padding: em(12, $pad-small);
  &:before {
    content: "";
    position: absolute;
    top: 0;
  }
  .is-ltr & {
    &:before {
      border-width: 0 em(12, 12px) em(12, 12px) 0;
      border-color: transparent $c-info transparent transparent;
      right: 100%;
    }
  }
  .is-rtl & {
    &:before {
      border-width: em(12, 12px) em(12, 12px) 0 0;
      border-color: $c-info transparent transparent transparent;
      left: 100%;
    }
  }
}
%imgCover {
  @extend %stretch;
  max-height: 100%;
  max-width: none;
}
.t-userPanel-image {
  width: running(250);
  height: running(250);
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  img {
    @extend %imgCover;
  }
}
.t-panelUser-noImage {
  display: block;
  width: 100%;
  height: 100%;
}
.t-panelUser-noImage-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: $c1-muted;
  color: $c1;
  i {
    font-size: 430%;
    display: inline-block;
    position: relative;
    .fa-slash {
      position: absolute;
      font-size: 100%;
      top: -99px;
      bottom: -99px;
      right: -99px;
      left: -99px;
      margin: auto;
      display: block;
      height: 1em;
    }
  }
}
.t-roundedImageFrame {
  display: flex;
  justify-content: center;
  align-items: center;
  width: running(40);
  height: running(40);
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  //margin: running(5);
  img {
    @extend %stretch;
    max-width: 100%;
    max-height: 100%;
  }
  .is-rtl & {
    margin-right: 0;
  }
  .is-ltr & {
    margin-left: 0;
  }
  &.is-spread {
    width: 100%;
    height: 0;
    padding: 100% 0 0 0;
    .t-userItemImage-noImage {
      width: 50%;
      height: 50%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      position: absolute;
      &:before {
        font-size: 140%;
      }
    }
  }
  &.is-square {
    border-radius: 0;
  }
}
.t-userItemImage-noImage {
  @extend %l-align-center;
  width: 100%;
  height: 100%;
  border: 1px solid;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  &.fa-image {
    &:after {
      content: "";
      width: 60%;
      height: 0;
      display: block;
      position: absolute;
      border-top: 2px solid;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      transform: rotate(45deg);
    }
  }
  &.is-noStyle {
    border: none;
  }
  + img {
    position: absolute;
    opacity: 0;
  }
}
.t-uiUser-sidebar {
  .is-clicked {
    background-color: $c1-muted;
    &:hover {
      background-color: darken($c1-muted, 10);
    }
  }
}

body .ui-inputswitch .ui-inputswitch-slider {
  background: #a0a2a5;
}
body .ui-inputswitch.ui-inputswitch-checked .ui-inputswitch-slider {
  background-color: $c1;
}

.t-badge {
  border-radius: 2px;
  font-size: 1em;
  color: white;
  padding: 0.3em;
  line-height: 1em;
  font-weight: 400;
}

.t-footnote {
  font-size: 0.8rem;
  color: $c-grey-med;
}

.t-italic {
  font-style: italic;
}

.t-popupCloseIcon {
  cursor: pointer;
  font-size: 1.3em;
  color: $c1;
  padding-bottom: 10px;
}

@mixin bg($c) {
  background-color: $c;
  color: #fff;
}
.bg-c1 {
  @include bg($c1);
}
.bg-c2 {
  @include bg($c2);
}
.bg-c3 {
  @include bg($c3);
}
.bg-transparent {
  @include bg(transparent);
  color: inherit;
}
.bg-c1-dark {
  @include bg($c1-dark);
}
.bg-dark {
  @include bg($c-dark);
}
.bg-warn {
  @include bg($c-warn);
}
.bg-item {
  @include bg($c-item);
}
.bg-info {
  @include bg($c-info);
  color: $c-grey;
}
.bg-grey {
  @include bg($c-grey);
}
.bg-border {
  @include bg($c-border);
  color: inherit;
}
.bg-grey-light {
  @include bg($c-grey-light);
  //color: $c-grey;
}
.bg-success {
  @include bg($c-success);
}
.bg-submit {
  @include bg($c-submit);
}
.bg-white {
  @include bg(#fff);
  color: inherit;
  &.c1 {
    color: $c1;
  }
}
.bg-purple {
  @include bg($c-purple);
}
.c1-dark {
  color: $c1-dark;
}
.c1 {
  color: $c1;
}
.c2-dark {
  color: $c2-dark;
}
.c-reject {
  color: $c-reject;
}
.c-link-light {
  color: $c-link-light;
}
.c-item {
  color: $c-item;
}
.c-warn {
  color: $c-warn;
}
.c3 {
  color: $c3;
}
.c-grey {
  color: $c-grey;
}
.c-grey-med {
  color: $c-grey-med;
}
.c-grey-light {
  color: $c-grey-light;
}
.c-purple {
  color: $c-purple;
}
.c-grey-accent {
  color: $c-grey-accent;
}
.c1-muted {
  color: $c1-muted;
}
.c-submit {
  color: $c-submit;
}
.c-success {
  color: $c-success;
}
.c-body {
  color: $c-body;
}

.fs-small {
  font-size: 0.85em;
}
.fs-smallest {
  font-size: 0.7em;
}
.fs-20 {
  font-size: running(18);
}
.fs-30 {
  font-size: running(22);
}
.fs-40 {
  font-size: running(26);
}
.fs-50 {
  font-size: running(30);
}

.fw-bold {
  font-weight: $fw-bold;
}
.fw-semibold {
  font-weight: $fw-semibold;
}
.fw-regular {
  font-weight: $fw-regular;
}
.t-pointer {
  cursor: pointer;
}

.t-chackbox-labeText {
  font-weight: $fw-semibold;
  color: $c1;
  font-size: 0.75rem;

  .large & {
    font-weight: unset;
    font-size: unset;
  }

  .c-success & {
    color: $c-success;
  }
}

@media (max-width: 800px) {
  %t-widget {
    padding: 20px 20px;
  }
}
