.page-header {
  border-bottom: 1px solid $c-border;
  padding: 1em;

  &.sticky {
    border-bottom-width: 2px;
    margin: 0 -20px;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 100;
  }

  .breadcrumbs {
    //margin-bottom: 1em;
    font-size: 0.9rem;
    position: relative;
    text-align: end;
  }

  .title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .title {
      max-width: 60%;
    }

    .buttons {
      align-items: unset;
    }
  }
}

@media (max-width: 1300px) {
  .page-header.responsive {
    .title-container {
      flex-direction: column;
      align-items: start;
      .title {
        max-width: 90%;
        margin-bottom: 1em;
      }
      div:not(.title) {
        align-self: flex-end;
      }
    }
  }
}

.t-small {
  zoom: 0.85;
}
.t-smaller {
  zoom: 0.9;
}

.filter-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  a {
    margin-left: 1em;
  }
}

.status-tag {
  background: $cat-color;
  color: white;
  font-size: 0.75rem;
  padding: 0.25rem 0.4rem;
  border-radius: 3px;
  display: inline-block;
  margin-left: 1.5em;
  position: absolute;
  font-weight: 500;

  .breadcrumbs & {
    top: -0.75em;
  }
}

.actions-container {
  display: flex;
  //flex-wrap: wrap;
  flex-direction: row;
}

// REVIEW

.rev-rule-type-descr {
  background: $cat-color;
  color: white;
  font-size: 0.75rem;
  padding: 0.25rem 0.4rem;
  border-radius: 3px;
  display: inline-block;
  margin-bottom: 0.2em;

  &.SPELLING {
    background-color: rgba(230, 163, 39, 0.911);
  }

  &.ISI {
    background-color: #2782d6;
  }

  &.BX_MATCH {
    background-color: #2782d6;
  }

}

.rev-rule-type-desc-tile {
  height: 37px;
  width: 74px;
  cursor: pointer;
  text-align: center;
}

.rev-rule-status {
  font-weight: $fw-semibold;

  &.PASSED,
  &.STET {
    color: $c-success;
  }

  &.NOT_PASSED,
  &.MARKED_FOR_CHANGES {
    color: $c-reject;
  }

  &.NOT_PASSED_NEW_CONTENT {
    color: $c-partial;
  }

  &.NOT_PASSED_VAR {
    color: #0978a2;
  }
}

.associatedText,
.associatedBy,
.reference,
.referencedBy,
.instructions,
.ext-ref,
.associatedImages {
  .title {
    color: $c2;
    margin-bottom: 0.5em;
    font-weight: $fw-bold;
  }

  .ref-container {
    // display: flex;
    // align-items: center;

    border: 1px solid;
    border-color: $c-quote-border;
    background-color: $c-quote-bg;
    margin: 0.5em 0;
    padding: 0.5em;
    width: 100%;
    overflow-wrap: anywhere;
  }

  .text {
    // border: 1px solid;
    // border-color: $c-quote-border;
    // background-color: $c-quote-bg;
    // margin: 0.5em 0;
    // padding: 0.5em;
    // width: 100%;
    // overflow-wrap: anywhere;
  }

  .ref-id {
    margin-top: 1em;
    font-style: italic;
    font-size: 0.8rem;
    color: $c-grey-med;
  }
}

.filter-bar {
  width: 100%;
  padding: 1em 0;
  align-items: center;

  .filters {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}

.action-divider {
  color: $c-grey-med;
  padding: 0 0.5em;
}

.rules-container {
  overflow-x: auto;

  rev-doc-rule-tile {
    margin-bottom: 1.5em;
  }

  &:last-child {
    margin: 0;
  }
}

.data-widget {
  min-width: 350px;
}

.data-widget-sm {
  width: 250px;
}

.canvas-bar-status-container {
  width: 200px;
  height: 20px;
  display: flex;
  justify-content: end;
  align-items: stretch;
}

.canvas-status-container {
  width: 200px;
  height: 180px;
  display: block;
  // padding-left: 2em;
}

.template-container {
  border: 1px solid $c-grey-med;
  padding: 1em;
  margin-top: 1.5em;
  position: relative;

  &:last-of-type {
    margin-bottom: 1.5em;
  }

  .template-phrase-txt {
    padding: 0.5em;
    border: 1px solid;
    border-color: #dee2e6;
    background-color: #f8f9fa;
    width: 100%;
    margin-bottom: 1em;

    &.highlight {
      border-width: 2px;
      border-color: $cat-color;
      background-color: #e9ecef;
    }

    .match-diff {
      &.missing {
        text-decoration: line-through;
        color: $c-reject;
      }

      &.extra {
        color: $c-reject;
      }
    }
  }

  .similarity {
    color: $cat-color;
    font-weight: $fw-semibold;
  }

  .diff-container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 1em;

    .diff-title {
      padding: 0.5em 1em;
      text-align: center;
      font-weight: $fw-semibold;
      display: flex;
      justify-content: center;
    }
  }

  .instructions {
    margin-top: 1em;
    background-color: white;
  }

  .match-id {
    margin-top: 0.75em;
    text-align: end;
    font-style: italic;
    font-size: 0.8rem;
    color: $c-grey-med;
  }
}

.card-match-similarity {
  &.FULL_MATCH {
    color: $c-success;
  }

  &.MISSING {
    color: $c-reject;
  }

  &.PARTIAL_MATCH {
    color: $c-partial;
  }
}

// CATALOG

.match-type-descr {
  background: $cat-color;
  color: white;
  font-size: 0.75rem;
  padding: 0.25rem 0.4rem;
  border-radius: 3px;
  display: inline-block;
  margin-bottom: 0.2em;
  margin-left: 8px;
  height: 37px;
  width: 74px;
  cursor: pointer;

  &.MATCH {
    background-color: $c-success;
  }

  &.NO_MATCH {
    background-color: $c-reject;
  }

  &.PARTIAL_MATCH {
    background-color: $c-partial;
  }

}

.cat-phrase-status {
  font-weight: $fw-semibold;

  &.APPROVE {
    color: $c-success;
  }

  &.APPROVE_VARIANT {
    color: $c-success;
  }

  &.REJECT {
    color: $c-reject;
  }
}

.cat-phrase-type {
  color: $cat-color;
  font-size: 0.85rem;
  //min-width: max-content;
  max-width: 500px;

  .sub-type {
    font-weight: $fw-light;
  }
}

.icon-edit {
  color: $c1;
  cursor: pointer;
  padding: 0 0.5em;
}

.processing-status {
  display: flex;
  align-items: center;
  font-style: italic;
  font-size: 0.85rem;
  color: $c-success;

  &.failed {
    color: $c-warn;
  }

  spinner {
    zoom: 50%;
  }
}

.footnote {
  font-style: italic;
  font-size: 0.8rem;
  color: $c-grey-med;
}

.nowrap {
  white-space: nowrap;
}

.pdfViewer .page {
  margin: 0 auto !important;
}

h2.images {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid $c-grey-med;
  line-height: 0.1em;
  margin: 2em 0;
  color: $c2-dark;
  font-size: 1rem;
  font-weight: 500;

  span {
    background: #fff;
    padding: 0 20px;
  }
}

.card-summary.doc-wrapper {
  margin-bottom: 1em;
}

.doc-container {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 10px;
  grid-template-areas: "title title status";
  .doc-title {
    grid-area: title;
  }

  .date {
    margin-top: 0.5em;
    font-size: 0.8rem;
    color: #9d9fa2;
  }

  .id {
    grid-area: id;
    text-align: end;
  }

  .status {
    grid-area: status;
    text-align: end;
  }
}

.box-tile {
  border-bottom: 1px solid lightgrey;
  &:last-child {
    border: none;
  }
}
