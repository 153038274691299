.g {
    display: grid;
    gap: running($pad-med);
    > * {
        min-height: 0;
        min-width: 0;
    }
}
.g-sidebarMain {
    display: grid;
    grid-template-columns: minmax(33%, running(350)) 1fr;
    grid-template-rows: auto 1fr;
    grid-column-gap: running($pad-med);
    grid-template-areas:
        "header header"
        "sidebar main";
    &.is-flip {
        grid-template-columns: 1fr minmax(25%, running(350));
        grid-template-areas:
            "header header"
            "main sidebar";
    }
    > header {
        grid-area: header;
    }
    > aside {
        grid-area: sidebar;
        min-height: 0;
    }
    > main {
        grid-area: main;
        min-height: 0;
        overflow-y: auto;
        overflow-x: visible;
    }
}

.g-headerBody {
    display: grid;
    gap: running($pad-med);
    grid-template-columns: auto;
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
        "header"
        "main"
        "footer";
    > header {
        grid-area: header;
    }
    > main {
        grid-area: main;
        min-height: 0;
        overflow-y: auto;
        overflow-x: visible;
    }
    > footer {
        grid-area: footer;
    }
}

.g-headerBody-flex {
    display: flex;
    flex-direction: column;
    > main {
        flex-grow: 1;
        height: 0;
        overflow-y: auto;
        overflow-x: visible;
        min-height: running(200);
    }
}

.g-tabSet {
    display: grid;
    grid-template-columns: auto;
    gap: running($pad-med);
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
        "tabList"
        "tabContainer";
    > .t-tablist {
        grid-area: tabList;
        margin: 0 1.14em;
        border-bottom: 2px solid #dee2e6;
        //padding-bottom: 0.6em;
        line-height: 2.5em;
    }
    > .t-tabContainer {
        grid-area: tabContainer;
        min-height: 0;
        overflow-y: visible;
        overflow-x: visible;
    }
}

.g-imageInfo {
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: running($pad-med);
    row-gap: running($pad-med);
    grid-template-areas: "figure info";
    > figure {
        grid-area: figure;
    }
    > main {
        grid-area: info;
        min-width: 0;
    }
}

.g-cards {
    display: grid;
    gap: running($pad-med);
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
}

.g-flowNavbar {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: running($pad-med);
}
.g-boxes-3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: running($pad-med);
    > * {
        margin: 0;
        min-height: 0;
        min-width: 0;
    }
}
.gi-span-2 {
    grid-column: 1 / 3;
}

// keep at bottom of file
.g--gapBig {
    gap: running($pad);
}
.g--gapSmall {
    gap: running($pad-small);
}
