//colors
$c1: #0978a2; // #0d89ec;
$c-body: white;
$c-border: #e0e0e0;
$c-success: #62bd60; // #53ac05;
$c-grey-med: #9d9fa2;
$c-grey-light: #e0e0e0;
$c1-light: #eaf7fd;
$c-bg-grey-light: #ebeef3;
$c-reject: #f35f3e;
$cat-color: #748ba0;
$c-quote-border: #dee2e6;
$c-quote-bg: #f8f9fa;
$c-more-border: #ced4da;
$c-txt: #434346;
$c-grey-accent: #ced4da;
$c-partial: #dbab23;
$c-hover: #eaf7ff;

$c2: #6e797e;
$c3: #cad9e4;
$c2-dark: #586368;
$c1-dark: #1972b4;
$c1-muted: #dff8d5;
$c-dark: #2f4858;
$c-link-light: #8dcdff;
$c-grey-d: #2f4858;
$c-grey: #2f4858;
$c-bg: #f4f4f4;
$c-info: #f2fafa;
$c-warn-light: #ffd787;
$c-warn: #e36848;
$c-item: #0288d1;
$c-submit: #00bcd4;
$c-searchSelect: #0288d1;
$c-searchFocus: #303f9f;
$c-purple: #6200ea;
$c-yellow-bright: #fff59d;
$c-accent: #31c47e;

//font weights
$fw-light: 300;
$fw-regular: 400;
$fw-semibold: 500;
$fw-bold: 600;

//radius
$rad1: 0px;
$rad-btn: 0px;
$rad-input: 0px;
$rad-popup: 0px;

//measurements
$pad-small: 8px;
$pad-med: 16px;
$pad: 32px;
$input-height: 36px;
$rad: 4px;

// sdiebar reveal
$sidebar-width: 50px;
$sidebar-nav-width: 200px;

$fs-default: 16px;
$fs-small: 14px;
$fs-btn: 14px;
$fs-large: 18px;

//shadows
$shadow-widget: 2px 0px 3px 4px #f8f9fa;
$shadow-focus: 0 0px 3px 0 rgba(0, 0, 0, 0.15); // 0px 0px 10px 0px rgba(0,0,0,0.1);
$shadow-focus-c1: 0px 0px 20px 0px rgba($c1, 0.2);

@function running($fs) {
  @return em($fs-default, $fs);
}
