@charset "utf-8";
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeng/resources/themes/saga-blue/theme.css";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/@fortawesome/fontawesome-free/css/all.min.css";

body,
html,
app-root,
app-layout {
    display: block;
    height: 100%;
}
@import "./assets/scss/variables";
@import "./assets/scss/mixins";
@import "./assets/scss/fonts";
@import "./assets/scss/norm";
@import "./assets/scss/layout";
@import "./assets/scss/theme";
@import "./assets/scss/grids";
@import "./assets/scss/prime-ng";
@import "./assets/scss/responsive";
@import "./assets/scss/application";
@import "./assets/scss/icons";
@import "./assets/scss/components/popup";
@import "./assets/scss/components/layout";
@import "./assets/scss/components/navbar";
@import "./assets/scss/components/misc";
@import "./assets/scss/components/table";

.fa-spinner {
    animation: fa-spin 1s infinite linear !important;
  }