.navbar-table {
  position: relative;
  z-index: 1;
  padding: 20px 20px;
  background-color: #fff;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.open-nav-btn {
  font-size: 1.5em;
  //padding: 0 .7em;
  margin-bottom: 3px;

  .is-rtl & {
    right: 40px;
  }
  .is-ltr & {
    left: 40px;
  }
}
