
@mixin use-md-symbol($symbol) {
    font-family: 'Material Symbols Outlined';
    font-weight: normal;
    font-style: normal;
    font-size: 18px; /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
  
    &:before {
      content: $symbol;
    }
  }

// Material Symbols
.task {
  @include use-md-symbol('task');
}
.spellcheck {
  @include use-md-symbol('spellcheck');
}
.rule {
  @include use-md-symbol('rule');
}
.warning {
  @include use-md-symbol('warning');
}
.emergency {
  @include use-md-symbol('emergency');
}
.priority-high {
  @include use-md-symbol('priority_high');
}
.format {
  @include use-md-symbol('format_color_text');
}
