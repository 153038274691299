
.item-desc-image {
    width: running( 180px );
    text-align: center;
    .is-rtl & {
        margin-left: $pad;
    }
    .is-ltr & {
        margin-right: $pad;
    }
}

.store-view-table {
    .w-quarter {
        width: 20%;
    }
}



@media (max-width: 1100px) {
    .ui-table {
        tr:not(.expanded) {
            > td:not(.t-pad-0), > th:not(.t-pad-0) {
                //font-size: 12px;
                //padding: 0.5em 0.3em !important;
            }
            td.t-pad-0 {
                padding:0;
                height: auto;
            }
        }
    }
    .t-tablet-block {
        display: block;
        > * {
            width: auto;
            height: auto;
        }
    }
}



/* Column Priorities */
@media only all {
    th.ui-p-7,
    td.ui-p-7,
    th.ui-p-6,
    td.ui-p-6,
    th.ui-p-5,
    td.ui-p-5,
    th.ui-p-4,
    td.ui-p-4,
    th.ui-p-3,
    td.ui-p-3,
    th.ui-p-2,
    td.ui-p-2,
    th.ui-p-1,
    td.ui-p-1 {
        display: none;
    }
}

/* Show priority 1 at 320px (20em x 16px) */
@media screen and (min-width: 20em) {
    th.ui-p-1,
    td.ui-p-1 {
        display: table-cell;
    }
}

/* Show priority 2 at 480px (30em x 16px) */
@media screen and (min-width: 30em) {
    th.ui-p-2,
    td.ui-p-2 {
        display: table-cell;
    }
}

/* Show priority 3 at 640px (40em x 16px) */
@media screen and (min-width: 40em) {
    th.ui-p-3,
    td.ui-p-3 {
        display: table-cell;
    }
}

/* Show priority 4 at 800px (50em x 16px) */
@media screen and (min-width: 50em) {
    th.ui-p-4,
    td.ui-p-4 {
        display: table-cell;
    }
}

/* Show priority 5 at 960px (60em x 16px) */
@media screen and (min-width: 60em) {
    th.ui-p-5,
    td.ui-p-5 {
        display: table-cell;
    }
}

/* Show priority 6 at 1,120px (70em x 16px) */
@media screen and (min-width: 70em) {
    th.ui-p-6,
    td.ui-p-6 {
        display: table-cell;
    }
}

/* Show priority 7 at 1,440px (90em x 16px) */
@media screen and (min-width: 90em) {
    th.ui-p-7,
    td.ui-p-7 {
        display: table-cell;
    }
}