* {
    box-sizing: border-box;
}
img {
    height: auto;
    border: none;
    vertical-align: bottom;
    max-width: 100%;
}
label {
    display: inline-block;
    margin-bottom: 0;
}
input[type="email"],
input[type="tel"] {
    direction: ltr;
}
input {
    font: inherit;
    border-radius: 0;
}
button {
    @extend %norm-btn;
}
button.fa {
    @extend %f-awsome;
}
.norm-ul {
    @extend %norm-ul;
}
dl,
dt,
dd,
figure,
section,
article,
main,
store-info,
data-box,
order-info {
    margin: 0;
    display: block;
}
a {
    cursor: pointer;
}
p {
    margin: 0;
    //margin: 0 0 $pad-med;
    &:last-child,
    &:only-child {
        margin: 0;
    }
}
code {
    display: block;
    overflow: auto;
    white-space: normal;
    direction: ltr;
}
[hidden] {
    display: none;
}
.norm-title {
    margin: 0;
    font: inherit;
}
.norm-button {
    &:hover {
        opacity: 0.8;
    }
    &.t-u {
        &:hover {
            opacity: inherit;
            text-decoration: none;
        }
    }
}
tbody:focus {
    outline: 1px solid rgba(#eeeeee, 0.6);
}
.norm-input {
    display: inline-block;
    width: auto;
    border: none;
    padding: 0;
    appearance: none;
    background-color: transparent;
}
h1 {
    color: $c2;
    margin: 0;
    font-size: 1.4rem;
    //padding-bottom: 0.5em;
}
h3 {
    font-size: 1.1rem;
}
strong {
    font-weight: $fw-semibold;
}
