@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500&display=swap');

body {
    font: 100%/1.1 sans-serif;
    font-family: Rubik, 'Open Sans', Arial, sans-serif;
    font-size: $fs-default;
    font-weight: $fw-regular;
    color: $c-txt;
    background-color: $c-body;
    margin: 0; 
    direction: ltr;
}
.is-rtl {
    direction: rtl;
}
.is-ltr {
    direction: ltr;
}
.is-ltr .sidebar  {
    direction: rtl;
}
.is-rtl .sidebar  {
    direction: ltr;
}
input-wrap {
    display: block;
}