/* == GENERAL ==*/

.p-component {
  //font-family: inherit !important;
  font-size: unset !important;
  font-weight: initial !important;
}

.p-button {
  padding: 0.7em 1.4em;
  font-size: 14px !important;
  border-radius: 0px !important;
}

/* == PROGRESS BAR == */
.p-progressbar .p-progressbar-value {
  background: $c1;
}
.p-progressbar .p-progressbar-label {
  color: white;
}

/* ==ORDER LIST ==*/

.p-orderlist-list-container {
  width: 60vw !important;
}
.p-orderlist-list {
  height: 500px !important;
}
.p-orderlist-item.cdk-drag-placeholder {
  opacity: 0.7 !important;
}

/* ==TAB MENU ==*/

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  border-color: $c1;
  color: $c1;
  text-decoration: none;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link,
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  font-weight: unset;
  text-decoration: none;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
  box-shadow: none;
}
.p-tabmenu .p-tabmenu-nav-btn.p-link {
  color: $c1;
}

/* == FILE UPLOAD == */

.p-fileupload .p-fileupload-content {
  display: none !important;
}

.p-fileupload .p-fileupload-buttonbar {
  background: transparent !important;
  padding: 0 !important;
  border: none !important;
}

.p-button.p-fileupload-choose {
  background-color: $c1 !important;
  border-color: $c1 !important;
}

/* == PANEL == */
.p-panel .p-panel-header {
  background: unset !important;
}

/* == SPLITTER == */

.p-splitter {
  border: none !important;
}
.p-splitter .p-splitter-gutter .p-splitter-gutter-handle {
  visibility: hidden !important;
}
.p-splitter-gutter {
  background: none !important;
  margin: 0 3px !important;
}

/* == ORDER LIST == */
.p-orderlist-list {
  max-height: calc(100vh - 150px) !important;
  min-height: unset;
}

.p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
  color: unset !important;
  background: unset !important;
}

.p-orderlist .p-orderlist-list .p-orderlist-item:focus {
  box-shadow: none !important;
}

.p-orderlist .p-orderlist-list .p-orderlist-item:not(.p-highlight):hover {
  background-color: unset !important;
}

.p-orderlist .p-orderlist-list .p-orderlist-item:not(.p-highlight):hover,
.p-orderlist .p-orderlist-list .p-orderlist-item:focus,
.p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
  .tile-container {
    border-color: $c1;
  }
}

.p-orderlist {
  .p-button {
    background: $c1 !important;
    border-color: $c1 !important;

    &:hover {
      background-color: darken($c1, 5%) !important;
    }
  }
}

/* == PANEL MENU == */

.p-panelmenu .p-panelmenu-header > a,
.p-panelmenu .p-panelmenu-content > a {
  background: inherit !important;
  border: 0 !important;
  color: inherit;
}

.p-panelmenu .p-panelmenu-header > a {
  &:hover {
    color: $c1 !important;
  }
}

.p-panelmenu .p-panelmenu-content {
  border: 0 !important;
}

.p-panelmenu .p-panelmenu-header > a:focus,
.p-panelmenu .p-panelmenu-content > a:focus,
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
  outline: none !important;
  box-shadow: none !important;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
  color: $c1 !important;
  background: inherit !important;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  .is-ltr & {
    padding-left: 2em;
  }
  .is-rtl & {
    padding-right: 2em;
  }
}

/* === TREE TABLE === */
.p-treetable table {
  table-layout: auto !important;
}

.p-treetable .p-treetable-tbody > tr {
  outline: none !important;
}

.p-treetable .p-treetable-thead > tr > th {
  position: sticky;
  top: 0;
  z-index: 3;
  box-shadow: 0 1px 0 rgba($c-border, 0.5);
}

/* === SELECT BUTTON === */

.instr-type-select {
  .p-buttonset .p-button {
    height: 30px;
  }
}

.spread-width {
  .p-selectbutton.p-buttonset {
    width: 100% !important;

    .p-button {
      width: 50% !important;
    }
  }
}

.default-buttonset {
  .p-buttonset .p-button {
    &.p-highlight {
      background-color: $c1 !important;
      border-color: $c1 !important;
    }
  }
}

/* === LIST BOX === */
.p-listbox .p-listbox-list .p-listbox-item {
  max-width: 100%; // fit-content;
  font-size: 0.9rem;
  align-items: start !important;
}

.p-listbox .p-listbox-list .p-listbox-item {
  color: initial !important;
  background: initial !important;
  outline: none !important;
  box-shadow: none !important;
}

/* === TEXT AREA === */

.p-inputtext {
  width: 100%;
}

.p-inputtext:enabled:focus {
  box-shadow: none !important;
}

.p-inputtext:enabled:hover,
.p-inputtext:enabled:focus {
  border-color: initial !important;
}

/* === TREE === */

.p-tree {
  padding: 0;
  font-size: 0.9rem;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
  //width: 1.5rem;
  //height: 1.5rem;

  &:focus {
    box-shadow: none;
  }
}

.p-tree {
  .p-checkbox,
  .p-checkbox-box {
    width: 18px;
    height: 18px;
  }
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
  color: #75767f;
}

.p-checkbox .p-checkbox-box.p-highlight {
  background: white;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
  color: $c1;
  background: white;
  font-size: 12px;
  font-weight: 600;
}

.p-highlight .p-checkbox .p-checkbox-box {
  border-color: $c1;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
  box-shadow: none;
}

.p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
  background: unset;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  //background: unset !important;
  color: white !important;
  background-color: $c1 !important;
}

/* === INPUT == */

.p-inputtext {
  border-radius: $rad-input;
  font-family: inherit;
  font-size: 0.9rem;
  font-weight: 300;
  line-height: 1.3;
  height: 34px;
}

/* === MULTISELECT == */
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  @extend %onfocus;
}

p-multiselect {
  display: block;
}

body .p-multiselect {
  border-color: $c-border !important;
  min-height: $input-height;
  width: 100% !important;
}

.is-rtl {
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox,
  .p-multiselect-panel .p-multiselect-header .p-checkbox {
    margin-left: 0.5rem !important;
    margin-right: 0 !important;
  }

  .p-multiselect-panel .p-multiselect-header .p-multiselect-close {
    margin-right: 0.5rem !important;
    margin-left: 0 !important;
  }
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  background: unset !important;
  color: unset !important;
}

.p-multiselect .p-multiselect-label {
  white-space: normal !important;
}

.p-multiselect .p-multiselect-trigger {
  border: none !important;
  color: $c-border !important;
}

.p-multiselect .p-multiselect-label.p-placeholder {
  color: #9d9fa2 !important;
  font-size: 1em !important;
  font-weight: 300 !important;
  font-family: inherit !important;
}

body .p-multiselect-panel .p-multiselect-header,
body .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  &:not([style="display: none;"]) {
    display: l-flex !important;
    align-items: center;
  }
}

body .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container,
body .p-multiselect-panel .p-multiselect-items .p-multiselect-item label {
  width: 0;
  flex-grow: 1;
}
.is-rtl .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container,
.is-rtl .p-multiselect-panel .p-multiselect-items .p-multiselect-item label {
  text-align: right;
}
.is-ltr .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container,
.is-ltr .p-multiselect-panel .p-multiselect-items .p-multiselect-item label {
  text-align: left;
}

body .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .ui-inputtext {
  padding: 0.43em;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.ui-state-highlight {
  background-color: $c-bg;
  color: inherit;
}
.p-multiselect-panel .p-multiselect-item {
  font-weight: inherit;
}
.p-multiselect-panel .p-multiselect-item {
  white-space: normal;
}
body .p-multiselect-panel {
  min-width: 200px !important;
  width: 100%;
}
.is-rtl .p-multiselect-panel {
  right: 0;
  left: auto !important;
}
.is-ltr .p-multiselect-panel {
  left: 0;
  right: auto !important;
}

.is-rtl .p-multiselect-panel .p-multiselect-item span {
  text-align: right;
}
.is-ltr .p-multiselect-panel .p-multiselect-item span {
  text-align: left;
}

/* === DROPDOWN == */

body .p-dropdown {
  width: 100% !important;
  //min-width: max-content;
  border-radius: $rad-input !important;
}

.is-rtl .p-dropdown-panel .p-dropdown-items .p-dropdown-item,
.is-rtl .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
  text-align: right;
}
.is-ltr .p-dropdown-panel .p-dropdown-items .p-dropdown-item,
.is-ltr .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
  text-align: left;
}

body .p-dropdown-panel .p-dropdown-items .p-dropdown-item,
body .ui-autocomplete-panel .ui-autocomplete-items .ui-autocomplete-list-item {
  border-bottom: 1px solid $c-border;
  padding: 0.6em 0.857em;
}

body .p-dropdown-panel .p-dropdown-header {
  border-top-right-radius: $rad-input;
  border-top-left-radius: $rad-input;
}

body .p-dropdown-panel .p-dropdown-items > p-dropdownitem:last-child .p-dropdown-item {
  border-bottom: 0;
  border-bottom-left-radius: $rad-input;
  border-bottom-right-radius: $rad-input;
}

body .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.ui-state-highlight):not(.ui-state-disabled):hover,
body
  .ui-autocomplete-panel
  .ui-autocomplete-items
  .ui-autocomplete-list-item:not(.ui-state-highlight):not(.ui-state-disabled):hover {
  background-color: $c1-light;
}

body .p-dropdown-panel .p-dropdown-filter-container {
  border-top-right-radius: $rad-input;
  border-top-left-radius: $rad-input;
}

body .p-dropdown-panel .p-dropdown-filter-container .p-dropdown-filter {
  font-size: 0.9em;
  border-radius: $rad-input;
}

body .p-dropdown-panel .p-dropdown-items .p-dropdown-item.ui-state-highlight,
body .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group.ui-state-highlight,
body .ui-autocomplete-panel .ui-autocomplete-items .ui-autocomplete-list-item.ui-state-highlight {
  background-color: $c1-light;
  color: inherit;
}
.p-dropdown-panel .p-dropdown-item {
  font-weight: inherit;
}

body .p-dropdown-panel,
body .ui-autocomplete-panel {
  border-radius: $rad-input;
}

.ui-autocomplete-panel .ui-autocomplete-list-item {
  white-space: normal;
}
body .ui-autocomplete-panel {
  min-width: 200px !important;
  width: 100%;
}

body .p-dropdown .p-dropdown-trigger {
  border-radius: $rad-input !important;
}

.p-dropdown .p-dropdown-trigger .p-dropdown-trigger-icon {
  border: none !important;
  color: $c-border !important;
}

body .p-dropdown .ui-state-focus,
body ui-autocomplete .ui-state-focus {
  outline: none !important;
}

body .p-dropdown {
  border-color: $c-border !important;
  min-height: $input-height;
}

.p-dropdown .p-dropdown-label,
.p-multiselect .p-multiselect-label {
  height: $input-height - 2;
  padding-top: 0.6em;
  padding-bottom: 0.7em;
  display: flex;
  align-items: center;
  font-weight: $fw-light;
  border-radius: $rad-input;
  color: $c-grey-d;
  white-space: normal;
  //max-width: fit-content;
}

body .ui-chkbox .ui-chkbox-box.ui-state-active {
  border-color: $c1;
  background-color: $c1;
}

body .p-dropdown-panel .p-dropdown-filter-container .p-dropdown-filter {
  padding-right: 0.42em;
  padding-left: 0.42em;
  font-weight: inherit;
}

/** SELECT BTN **/

.p-button:focus {
  box-shadow: none !important;
}

.p-buttonset {
  width: max-content;
}

.rev-status {
  .p-buttonset .p-button:first-of-type {
    &.p-highlight {
      background-color: $c-reject !important;
      border-color: $c-reject !important;
      color: white !important;
    }
  }
  .p-buttonset .p-button:last-of-type {
    &.p-highlight {
      background-color: $c-success !important;
      border-color: $c-success !important;
      color: white !important;
    }
  }
}

.cat-phrase-status {
  .p-buttonset .p-button:first-of-type {
    &.p-highlight {
      background-color: $c-success !important;
      border-color: $c-success !important;
      color: white !important;
    }
  }

  .p-buttonset .p-button:last-of-type {
    &.p-highlight {
      background-color: $c-reject !important;
      border-color: $c-reject !important;
      color: white !important;
    }
  }
}

.p-selectbutton {
  .p-button {
    padding: 0.7em 1.4em !important;
    font-size: 0.85rem !important;
  }
}


.rev-status .p-selectbutton .p-button {
  padding: 0.7em 1em !important;
  font-size: 0.85rem !important;
}

/* === TABLE === */

.p-datatable table {
  table-layout: auto !important;
}

.p-datatable .p-datatable-tbody > tr {
  &.selected {
    //background-color: $c1-light;
    //font-weight: $fw-semibold;
  }
}

.p-datatable {
  table {
    position: relative;
  }
  thead {
    th {
      position: sticky;
      top: 0;
      background: #fff;
      z-index: 3;
      box-shadow: 0 1px 0 rgba($c-border, 0.5);
    }
  }
}

.p-datatable .p-datatable-thead > tr > th {
  .t-small & {
    padding: 0.5rem 1rem;
    // color: white;
    // background: darken(#f8f9fa, 40%); //$c2-dark;
  }
}

.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
  background: initial !important;
  color: inherit !important;
}

// .t-hover-tr:hover td {
//   background-color: white !important;
// }

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: $c1 !important;
  border-color: $c1 !important;
  color: white !important;
}

/* === ui emements == */
body .ui-widget,
body .ui-inputtext {
  font-weight: $fw-regular;
  font-family: inherit !important;
}
.ui-inputwrapper-focus {
  @extend %onfocus;
}

body .ui-inputtext:enabled:focus:not(.ui-state-error) {
  box-shadow: 0 0 0 0.2em #edf0f5 !important;
  border-color: #c8c8c8;
}

.t-input--colored {
  .ui-autocomplete .ui-autocomplete-input {
    width: 100%;
    background-color: $c-body;
    border: 0;
    border-radius: 26px;
    min-height: $input-height;
  }

  .p-dropdown {
    border: 0 !important;
  }
  .p-dropdown .p-dropdown-label {
    background-color: $c-body;
    font-weight: $fw-regular;
    color: $c-grey;

    &.ui-placeholder {
      color: $c-grey-med;
    }
  }

  .p-dropdown .p-dropdown-trigger {
    background-color: $c-body;
  }
  .p-dropdown .p-dropdown-trigger .p-dropdown-trigger-icon:after {
    color: $c-grey-light;
  }
  .p-dropdown .p-dropdown-trigger {
    height: 96%;
  }
  .ui-state-disabled {
    opacity: 0.85;
  }
}

// tables

p-table {
  display: block;
  &.is-loading {
    position: relative;
    overflow: hidden;
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
    &:before {
      z-index: 10;
      background: rgba(#fff, 0.7);
    }
    &:after {
      z-index: 11;
      width: 20px;
      height: 20px;
      border: 4px solid $c1;
      border-left-color: transparent;
      border-radius: 50%;
      @include spin();
    }
  }
}
th {
  text-align: inherit;
}

.p-datatable .p-datatable-tbody > tr.selected {
  background-color: $c-hover;
}

body .ui-table .ui-table-thead > tr > th {
  font-weight: $fw-semibold;
  color: $c1;
  font-size: 1em;
  text-align: start;
}

body .ui-table .ui-table-thead > tr > th,
body .ui-table .ui-table-tbody > tr > td {
  background-color: #fff;
  border: none;
  border-bottom: 1px solid rgba($c-border, 0.7);
}

body .ui-table .ui-table-tbody > tr:last-child {
  td {
    border: none;
  }
}

body .table-align-top .ui-table .ui-table-tbody > tr > td {
  vertical-align: top;
}
.is-rtl .ui-table .ui-table-tbody > tr > td,
.is-rtl .ui-table .ui-table-tbody > tr > th {
  &.t-border--ltrLeft {
    border: none;
    border-right: 1px solid $c-border;
    border-bottom: 1px solid rgba($c-border, 0.7);
  }
  &.t-border--ltrRight {
    border: none;
    border-left: 1px solid $c-border;
    border-bottom: 1px solid rgba($c-border, 0.7);
  }
}
.is-ltr .ui-table .ui-table-tbody > tr > td,
.is-rtl .ui-table .ui-table-tbody > tr > th {
  &.t-border--ltrLeft {
    border: none;
    border-left: 1px solid $c-border;
    border-bottom: 1px solid rgba($c-border, 0.7);
  }
  &.t-border--ltrRight {
    border: none;
    border-right: 1px solid $c-border;
    border-bottom: 1px solid rgba($c-border, 0.7);
  }
}

body .ui-table .ui-table-tbody > tr.expanded > td {
  padding: $pad-med;
}
.t-pad-0 {
  height: auto !important;
  padding: 0 !important;
  border-top: none !important;
  border-bottom: none !important;
}
.clear-muilti-cell {
  color: $c1;
  .is-rtl & {
    margin-right: 5px;
  }
  .is-ltr & {
    margin-left: 5px;
  }
}
.has-hidden-cell + tr td {
  border-top: none !important;
}
@mixin nearFocused($dir) {
  > td {
    background: linear-gradient(to $dir, #ffffff 54%, #ffffff 54%, #f2f2f2 100%);
  }
}
.ui-widget tr:focus {
  outline: 2px solid $c1;
}

// calendar
p-calendar {
  display: block;
  position: relative;
  background: transparent; // #fff;
  height: $input-height;
  border: 1px solid $c-border;
  border-radius: 26px; //4px;
  &.p-calendar-inline {
    height: auto;
    border: none;
    .ui-datepicker {
      border: none;
    }
    &:after {
      display: none;
    }
    table tr td {
      text-align: center;
      &.ui-datepicker-today {
        a {
          background-color: transparent;
        }
      }
    }
  }
  &.notifications-calendar {
    th,
    td,
    td > span {
      text-align: inherit !important;
    }
    td {
      border: 1px solid $c-border;
      height: running(90);
      vertical-align: top;
      padding: 0 !important;
    }
    table {
      table-layout: fixed;
    }
    table tr td a {
      display: block;
      background: transparent !important;
      color: inherit;
      width: auto;
      text-align: inherit;
      padding: 0;
      &.ui-state-active {
        color: inherit !important;
      }
    }
    .ui-datepicker-prev,
    .ui-datepicker-next {
      display: none !important;
    }
    .ui-datepicker-other-month {
      opacity: 0.5;
      > a {
        pointer-events: none;
      }
    }
  }
  &:after {
    @extend %l-align-center;

    background: url("../../assets/img/Calendar.svg");
    content: "";
    background-position: center;
    background-repeat: no-repeat;
    color: #edf0f5 !important;
    position: absolute;
    width: 22px;
    height: 22px;
    z-index: 2;
    margin: 5px 15px;
    top: 0;

    /*
    @extend %f-awsome;
    content: "\f073";
    color: $c-border !important;
    position: absolute;
    width: $input-height;
    height: 100%;
    z-index: 2;
    top: 0;*/

    .is-rtl & {
      left: 0;
    }
    .is-ltr & {
      right: 0;
    }
  }
  .ui-calendar {
    display: block;
    position: static;
    height: 100%;
  }
  .ui-inputtext {
    position: relative;
    z-index: 2;
    border-width: 0;
    border-radius: 26px;
    width: 100%;
    height: 100%;
    cursor: pointer;

    .is-rtl & {
      padding-right: 20px;
    }
    .is-ltr & {
      padding-left: 20px;
    }
  }
  .t-noteRule.is-past {
    opacity: 0.5;
  }
}

p-calendar {
  &.p-append-top {
    .ui-datepicker {
      top: -240px !important;
    }
  }
}

.t-input--colored {
  .ui-inputtext {
    background-color: $c-body;
    font-weight: $fw-regular;
  }
}

.ui-button-secondary {
  background-color: transparent !important;
  color: $c1 !important;
  border: 2px solid $c1 !important;
  border-radius: 26px !important;
  box-shadow: none !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

p-calendar .ui-inputtext {
  font-weight: $fw-light;
  @include placeholder($c-grey-med);
}

%highlihgtCalEl {
  outline: 3px solid $c1;
}
// row level
.is-calendarSelectedByWeek .notifications-calendar .is-hasActiveCell {
  @extend %highlihgtCalEl;
  &.has-pastDates {
    outline-color: $c-grey-light;
  }
}
// cell level
.is-calendarSelectedByDay .notifications-calendar .ui-datepicker-current-day {
  @extend %highlihgtCalEl;
  &.is-pastDate {
    outline-color: $c-grey-light;
  }
}
.ui-datepicker-today {
  //background: $c1-light;
}
.notifications-calendar-dateDisblay {
  background-color: rgba($c1-muted, 0.2);
  display: block;
  text-align: inherit;
  font-size: inherit;
  color: inherit;
  &[disabled] {
    cursor: default;
    background: transparent;
  }
  &:hover:not([disabled]) {
    background-color: rgba($c1-muted, 0.4);
    .fa-pen {
      opacity: 0.8;
    }
  }
  .fa-pen {
    opacity: 0.2;
  }
}
.notificationExc-row {
  color: $c-dark;
  padding: 0.2em 0.2em;
  width: 100%;
}

.ui-widget-header .ui-inputtext,
.ui-widget-content .ui-inputtext {
  font-weight: inherit;
  height: 100%;
}

.p-dropdown-panel .p-dropdown-items-wrapper {
  min-width: 130px;
  margin: 0 5px;
  max-height: 300px !important;
}
// CALENDAR
body .ui-datepicker {
  select {
    border: none;
    background-color: transparent;
  }
  padding: 0;
}
.ui-datepicker:not(.ui-datepicker-inline) {
  top: auto !important;
  border-radius: 26px;
}

body .ui-datepicker .ui-datepicker-header {
  background-color: $c1;
  color: white;
  font-weight: $fw-semibold;
  padding: 0.5em 0;
  border-top-right-radius: 26px;
  border-top-left-radius: 26px;

  .ui-datepicker-prev,
  .ui-datepicker-next {
    padding: 1em 1.5em;
  }
}

body .ui-datepicker-calendar-container thead {
  background-color: $c1-light;
  margin: 0;

  th {
    font-weight: $fw-semibold;
    padding: 0.5em 0 !important;
  }
}

body .ui-datepicker table {
  margin: 0;
  font-size: 0.9em;
}

//calendar cells padding
body .ui-datepicker table td > a,
body .ui-datepicker table td > span,
body .ui-datepicker table td,
body .ui-datepicker table th {
  padding: 0.2em;
}
.ui-datepicker-month,
.ui-datepicker-year {
  margin: 0 0.25em;
}
// calendar arrows
body .ui-datepicker .ui-datepicker-header .ui-datepicker-prev,
body .ui-datepicker .ui-datepicker-header .ui-datepicker-next {
  @extend %l-align-center;
  @extend %f-awsome;
  color: white;

  &:hover {
    color: white;
  }
}

.ui-datepicker .ui-datepicker-prev {
  .is-rtl & {
    right: 0.125em;
    left: auto;
  }
  .is-ltr & {
    left: 0.125em;
    right: auto;
  }
}
.ui-datepicker .ui-datepicker-next {
  .is-ltr & {
    right: 0.125em;
    left: auto;
  }
  .is-rtl & {
    left: 0.125em;
    right: auto;
  }
}
.is-rtl .ui-datepicker .ui-datepicker-header .ui-datepicker-prev span:before {
  content: "\f105"; //angle-right
}
.is-rtl .ui-datepicker .ui-datepicker-header .ui-datepicker-next span:before {
  content: "\f104"; //angle-left
}
.is-ltr .ui-datepicker .ui-datepicker-header .ui-datepicker-prev span:before {
  content: "\f104"; //angle-left
}
.is-ltr .ui-datepicker .ui-datepicker-header .ui-datepicker-prev span:before {
  content: "\f105"; //angle-right
}
.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
  position: static;
  display: block;
  margin: 0;
}

body .ui-datepicker .ui-datepicker-header .ui-datepicker-next span:before {
  content: "\f105";
}

body .ui-datepicker .ui-datepicker-header .ui-datepicker-prev span:before {
  content: "\f104";
}
body .ui-datepicker .ui-datepicker-buttonbar .ui-g {
  display: flex;
  justify-content: space-between;
  .ui-g-6 {
    width: auto;
  }
  .ui-button {
    color: $c1;
    border: 2px solid $c1;
    box-shadow: none;
    background-color: transparent;
    border-radius: 26px;
    font-weight: $fw-semibold;
    min-width: 80px;
    font-size: 0.9em;

    &:hover {
      color: darken($color: $c1, $amount: 10);
      border-color: darken($color: $c1, $amount: 10);
      background-color: initial;
    }
  }

  &::after {
    display: none;
  }
}
body .ui-datepicker table td.ui-datepicker-today a.ui-state-active,
body .ui-datepicker table td > a.ui-state-active {
  background-color: $c1;
  color: #fff;
  border-radius: 50%;
  padding: 0.5em;
}

body .ui-datepicker table td.ui-datepicker-today > a {
  background-color: $c1-light;
  border-radius: 50%;
  //color: white;
}

.ui-inputnumber-buttons-stacked .ui-inputnumber-input {
  width: 60px;
}

.dirty .ui-inputnumber-buttons-stacked .ui-inputnumber-input {
  color: red;
}

.ui-inputnumber-buttons-stacked .ui-inputnumber-button-group .ui-inputnumber-button {
  background-color: $c1;
  font-size: 0.6em;
}

.pi-chevron-up {
  @extend %f-awsome;
  &:before {
    content: "\f077";
  }
}

.pi-chevron-down {
  @extend %f-awsome;
  &:before {
    content: "\f078";
  }
}

// TABLE

body .ui-paginator .ui-paginator-current {
  font-weight: $fw-regular;
  font-size: 0.9em;
  color: $c-grey-med;
  position: absolute;
  display: none;
}

body .ui-paginator a.ui-state-disabled {
  color: $c-grey-med !important;
}

.is-rtl {
  .ui-paginator .ui-paginator-current {
    right: 0;
  }
}

.is-ltr {
  .ui-paginator .ui-paginator-current {
    left: 0;
  }
}

body .ui-paginator .ui-paginator-first,
body .ui-paginator .ui-paginator-prev,
body .ui-paginator .ui-paginator-next,
body .ui-paginator .ui-paginator-last {
  color: $c1;
}

body .ui-paginator {
  display: flex;
  justify-content: flex-end;
  .ui-paginator-element {
    border-radius: 50%;
  }
}
/*.is-ltr {
  .ui-paginator-prev {
    order: 5000;
  }
  .ui-paginator-first {
    order: 5001;
  }
  .ui-paginator-next {
    order: -1;
  }
  .ui-paginator-last {
    order: -2;
  }
}*/
.ui-table {
  //  overflow-x: auto;
  // overflow-y: visible;
  -webkit-overflow-scrolling: touch;
}

$table-pad-x: 0.8em; //here
$table-pad-y: 0.5em;
.ui-table table {
  table-layout: auto;
  border-collapse: collapse;
}
.is-table-fixed .ui-table table {
  table-layout: fixed;
}
body .ui-table .ui-table-tbody > tr:not(.is-focused) {
  outline: none !important;
  color: inherit;
  &:focus {
    outline: rgba($c1, 0.4) auto 1px !important;
  }
  &.bg-success > td {
    background-color: "#f8f9fc" !important; // rgba($c1, 0.1) !important;
  }
}
body .ui-table .ui-table-tbody > tr > td {
  img {
    max-width: 100%;
  }
}

body .ui-table .ui-table-tbody > tr:nth-child(even).ui-state-highlight,
body .ui-table .ui-table-tbody > tr.ui-state-highlight {
  color: inherit;
  background-color: rgba($c-border, 0.5);
  &:focus {
    outline-color: $c1 !important;
    outline: $c1 auto 5px !important;
  }
}

.ui-table .ui-table-tbody > tr > td.ui-editing-cell,
body .ui-table .ui-table-tbody > tr > td {
  padding: $table-pad-y $table-pad-x;
}

.table-fs-small {
  td,
  th {
    font-size: 12px;
  }
}

body .ui-table .ui-table-thead > tr > th {
  transform: translate(0px);
}
body .ui-table .ui-table-thead > tr > th {
  //height: running(60);
  height: 62px;
}

body .ui-table .ui-table-tbody > tr > td {
  //height: running(40);
  height: 60px;
  overflow-wrap: break-word;
}

.dense {
  .p-datatable .p-datatable-tbody > tr > td {
    padding: 0 !important;
  }
}

.is-rtl p-table:not(.dense) {
  .ui-table .ui-table-tbody > tr > td:first-child,
  .ui-table .ui-table-thead > tr > th:first-child {
    padding-right: 40px;
  }

  .ui-table .ui-table-tbody > tr > td:last-child,
  .ui-table .ui-table-thead > tr > th:last-child {
    padding-left: 40px;
  }
}

.is-ltr p-table:not(.dense) {
  .ui-table .ui-table-tbody > tr > td:first-child,
  .ui-table .ui-table-thead > tr > th:first-child {
    padding-left: 40px;
  }

  .ui-table .ui-table-tbody > tr > td:last-child,
  .ui-table .ui-table-thead > tr > th:last-child {
    padding-right: 40px;
  }
}

.is-rtl .tbl-round-bottom {
  .ui-table .ui-table-tbody > tr:last-child > td:first-child {
    border-radius: 0 0 26px 0 !important;
  }

  .ui-table .ui-table-tbody > tr:last-child > td:last-child {
    border-radius: 0 0 0 26px !important;
  }
}

.is-ltr .tbl-round-bottom {
  .ui-table .ui-table-tbody > tr:last-child > td:first-child {
    border-radius: 0 0 0 26px !important;
  }

  .ui-table .ui-table-tbody > tr:last-child > td:last-child {
    border-radius: 0 0 26px 0 !important;
  }
}

.is-rtl .tbl-color {
  .ui-table .ui-table-thead > tr > th:first-child {
    border-radius: 0 26px 0 0;
  }

  .ui-table .ui-table-thead > tr > th:last-child {
    border-radius: 26px 0 0 0;
  }
}

.is-ltr .tbl-color {
  .ui-table .ui-table-thead > tr > th:first-child {
    border-radius: 26px 0 0 0;
  }

  .ui-table .ui-table-thead > tr > th:last-child {
    border-radius: 0 26px 0 0;
  }
}

.tbl-color {
  .ui-table .ui-table-thead > tr > th {
    color: white;
    background-color: $c1;
  }
}

.cell-checkbox {
  width: calc(#{$table-pad-x * 2} + 20px);
}
.cell-active-user,
.cell-editUser,
.cell-moreInfo,
.cell-readSw,
.cell-50 {
  width: running(50px);
}
.cell-user-id,
.cell-os,
.cell-100 {
  width: running(100px);
}
.cell-150 {
  width: running(150px);
}
.cell-app-ver,
.cell-opened,
.cell-push,
.cell-notifId,
.cell-noteId,
.cell-storeNoteId,
.cell-notificationId,
.cell-70 {
  width: running(70px);
}
.cell-date,
.cell-messageType,
.cell-image,
.cell-imgSize2Url,
.cell-imgUrl,
.cell-phone,
.cell-130 {
  width: running(130);
}
.cell-200,
.cell-bundleName {
  width: running(200);
}
.cell-email {
  width: running(220px);
}
.cell-email-view {
  direction: ltr;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cell-stage {
  min-width: 100px;
}

.is-new-user {
  td,
  th {
    position: relative;
    background-color: $c-success !important;
    color: $c1;
  }
}
.ui-table tr.is-new-user:not(.expanded) > td,
.ui-table tr.is-new-user:not(.expanded) > th {
  padding-bottom: 20px !important;
}
.btn-new-user {
  position: absolute;
  background: $c1;
  color: #fff;
  padding: 0.3em 1em 0.4em;
  font-size: running(12px);
  height: em(12, 20px);
  border-radius: 3px;
  white-space: nowrap;
  bottom: 0;
  z-index: 9;
  @extend %onfocus;
  .is-rtl & {
    right: 0;
  }
  .is-ltr & {
    left: 0;
  }
}

// table pagination
.ui-paginator-icon {
  @extend %f-awsome;
}
body .ui-paginator .ui-paginator-pages {
  height: auto;
  display: table-cell !important;
}
body .ui-paginator .ui-paginator-pages .ui-paginator-page {
  border-radius: 50%;
  border: 2px solid transparent;
  margin: running($pad-small/2);
  line-height: 2.17em;
}
body .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active {
  color: #0094da;
  border-color: #0094da;
  background-color: transparent;
  font-weight: 500;
  box-shadow: none;
}
body .ui-paginator {
  border: none;
  align-items: center;
  padding: 1em;
  background-color: transparent;
}

.ui-table .ui-paginator-top {
  display: none;
}

body .ui-table-wrapper {
  //margin-top: 20px;
  width: 100%;
  overflow-x: auto;
}

.is-ltr .pi-caret-left {
  &:before {
    content: "\f104"; //angle-right
  }
}
.is-ltr .pi-caret-right {
  &:before {
    content: "\f105"; //angle-left
  }
}
.is-ltr .pi-step-forward {
  &:before {
    content: "\f101";
  }
}
.is-ltr .pi-step-backward {
  &:before {
    content: "\f100";
  }
}

.is-rtl .pi-caret-left {
  &:before {
    content: "\f105"; //angle-right
  }
}
.is-rtl .pi-caret-right {
  &:before {
    content: "\f104"; //angle-left
  }
}
.is-rtl .pi-step-forward {
  &:before {
    content: "\f100";
  }
}
.is-rtl .pi-step-backward {
  &:before {
    content: "\f101";
  }
}

// SELECT BUTTON
.ui-selectbutton {
  display: flex;
  flex-wrap: wrap;
}
body .ui-selectbutton .ui-button {
  border-radius: $rad !important;
  margin-bottom: 5px;
  padding: 5px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}
.is-rtl .ui-selectbutton .ui-button {
  margin-left: 5px;
}
.is-ltr .ui-selectbutton .ui-button {
  margin-right: 5px;
}
body .ITEM_INFO_COMPONENT-selectButtons .ui-selectbutton .ui-button {
  padding: 0;
}
.ui-table {
  table {
    position: relative;
  }
  thead {
    th {
      position: sticky;
      top: 0;
      background: #fff;
      z-index: 3;
      box-shadow: 0 1px 0 rgba($c-border, 0.5);
    }
  }
}

// range slider
%slider-shadow {
  box-shadow: inset -1px 2px 6px 0px rgba(0, 0, 0, 0.3);
}
body .ui-slider {
  @extend %slider-shadow;
  background-color: $c-border;
}
body .ui-slider .ui-slider-range {
  @extend %slider-shadow;
  background-color: $c1;
}
body .ui-slider.ui-slider-horizontal {
  height: 0.55em;
}
body .ui-slider .ui-slider-handle,
body .ui-slider.ui-slider-horizontal .ui-slider-handle {
  @extend %slider-shadow;
  border: 1px solid $c-border;
  display: block;
  width: 1.5em;
  height: 1.5em;
  top: -2em;
  bottom: -2em !important;
  margin: auto;
  &:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid #fff;
    display: block;
    box-sizing: border-box;
  }
  &:focus {
    outline: none;
    box-shadow:
      inset -1px 2px 6px 0px rgba(0, 0, 0, 0.3),
      0px 0px 20px 0px rgba(50, 50, 50, 0.75);
  }
}
body .ui-slider:not(.ui-state-disabled) .ui-slider-handle:hover {
  border-color: $c1;
  border-width: 1px;
}

.rangeSliderMark {
  position: absolute;
  top: calc(100% + #{running(4px)});
  left: -20px;
  right: -20px;
  margin: auto;
  text-align: center;
  > span {
    display: inline-block;
    vertical-align: bottom;
    padding: running(2) running(4);
    font-size: running(12);
    background-color: #fff;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
    border-radius: running(2);
  }
}

// progress bar
.ui-progressbar .ui-progressbar-value {
  background: #fcad26 !important;
}

// overlay panel

body .ui-overlaypanel {
  background-color: $c-body;
  border-radius: 26px;
  margin-top: 0;
  border-top: 0;
}

body .ui-overlaypanel::before,
body .ui-overlaypanel::after {
  display: none;
}

.ui-overlaypanel .ui-overlaypanel-close {
  background-color: $c1 !important;
}

body .ui-overlaypanel .ui-overlaypanel-content {
  padding: 0;
}

body .ui-overlaypanel {
  padding: 0;
  border-color: $c-grey-light;
  &.ui-shadow {
    box-shadow: $shadow-focus; // 0 1px 0px 0 rgba(0, 0, 0, 0.3);
  }
}

.userNotificationPanel {
  .ui-overlaypanel {
    max-height: 50vh;
    overflow-y: auto;
  }
}

.userOptionsPanel,
.resultsPerPagePanel {
  .ui-overlaypanel {
    margin-top: 0.5em;
    padding: 0.2em;
  }
}

.resultsPerPagePanel {
  .ui-overlaypanel .ui-overlaypanel-content {
    padding: 0.5em;
  }
}

// menu bar
.ui-menubar .ui-menuitem-link {
  direction: rtl !important;
}

.ui-menubar .ui-menubar-root-list > .ui-menuitem > .ui-menuitem-link {
  padding: 0.5em;
}

// tab view
.ui-tabview.ui-tabview-top .ui-tabview-nav li a {
  padding: 0.4em 1.2em !important;
}

.ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active {
  background-color: #666666 !important;
  border: 1px solid #666666 !important;
}

// tab menu

.ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem {
  float: right !important;
}

.ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem.ui-state-active {
  background-color: $c1 !important;
  border: 1px solid $c1 !important;
}

/* Maria - MISC */

.ui-chkbox-label {
  margin: 0 0.5em 1em !important;
}

.ui-inputtext {
  font-family: inherit !important;
}

.tbl-auto {
  .ui-table table {
    table-layout: auto !important;
  }
}

.menu-pad-right {
  padding-right: 0.2em !important;
}

.menu-pad-left {
  padding-left: 0.2em !important;
}

.panel-m-left {
  margin-left: 2em;
}

// auto complete
.ui-autocomplete {
  width: 100%;

  .ui-inputtext:enabled:focus:not(.ui-state-error) {
    border: 0 !important;
  }
}

/**** MEDIA QUERY ****/

@media (max-width: 390px) {
  .is-rtl p-table:not(.dense) {
    .ui-table .ui-table-tbody > tr > td:first-child,
    .ui-table .ui-table-thead > tr > th:first-child {
      padding-right: 20px;
    }

    .ui-table .ui-table-tbody > tr > td:last-child,
    .ui-table .ui-table-thead > tr > th:last-child {
      padding-left: 20px;
    }
  }

  .is-ltr p-table:not(.dense) {
    .ui-table .ui-table-tbody > tr > td:first-child,
    .ui-table .ui-table-thead > tr > th:first-child {
      padding-left: 20px;
    }

    .ui-table .ui-table-tbody > tr > td:last-child,
    .ui-table .ui-table-thead > tr > th:last-child {
      padding-right: 20px;
    }
  }

  .is-rtl p-table:not(.dense) {
    .ui-table .ui-table-tbody > tr > td:first-child,
    .ui-table .ui-table-thead > tr > th:first-child {
      padding-right: 20px;
    }

    .ui-table .ui-table-tbody > tr > td:last-child,
    .ui-table .ui-table-thead > tr > th:last-child {
      padding-left: 20px;
    }
  }

  .is-ltr p-table:not(.dense) {
    .ui-table .ui-table-tbody > tr > td:first-child,
    .ui-table .ui-table-thead > tr > th:first-child {
      padding-left: 20px;
    }

    .ui-table .ui-table-tbody > tr > td:last-child,
    .ui-table .ui-table-thead > tr > th:last-child {
      padding-right: 20px;
    }
  }
}

@media (min-width: 800px) {
  body .ui-paginator .ui-paginator-current {
    display: initial;
  }

  .ui-table .ui-paginator-top {
    display: flex;
  }

  body .ui-table-wrapper {
    //margin-top: 0;
  }
}
