/*
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: $fw-light;
  src: local('Open Sans Light'), local('OpenSans-Light'),
  url(./assets/fonts/open-sans-hebrew/opensanshebrew-light.woff) format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: $fw-regular;
  src: local('Open Sans'), local('OpenSans'),
  url(./assets/fonts/open-sans-hebrew/opensanshebrew-regular.woff) format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: $fw-bold;
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
  url(./assets/fonts/open-sans-hebrew/opensanshebrew-bold.woff) format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: $fw-light-italic;
  src: local('Open Sans Light Italic'), local('OpenSansLight-Italic'),
  url(./assets/fonts/open-sans-hebrew/opensanshebrew-lightitalic.woff) format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: $fw-italic;
  src: local('Open Sans Italic'), local('OpenSans-Italic'),
  url(./assets/fonts/open-sans-hebrew/opensanshebrew-italic.woff) format('woff');
}*/

// rubik

/*@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: $fw-light;
  src: local('Rubik-Light'),
  url(./assets/fonts/rubik/Rubik-Light.ttf) format('opentype');
}
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight:  $fw-regular;
  src: local('Rubik-Medium'),
  url(./assets/fonts/rubik/Rubik-Medium.ttf) format('opentype');
}
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight:  $fw-bold;
  src: local('Rubik-Bold'),
  url(./assets/fonts/rubik/Rubik-Bold.ttf) format('opentype');
}
*/




/*@font-face {
  font-family: Assistant;
  src: url(assets/fonts/Assistant-Regular.ttf) format("opentype");
}

@font-face {
  font-family: Assistant;
  font-weight: 300;
  src: url(assets/fonts/Assistant-SemiBold.ttf) format("opentype");
}*/
