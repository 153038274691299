.container-wrap {
  position: relative;
  z-index: 3;
  transition: all 250ms;

  &.mobile-nav-open {
    display: none;
    transform: translateX(-100%);
  }
}
.layout-table {
  overflow: hidden;
}
.is-ltr .is-menu-open {
  .container-wrap {
    transform: translateX(-($sidebar-nav-width - $sidebar-width));
  }
}
.is-rtl .is-menu-open {
  .container-wrap {
    transform: translateX($sidebar-nav-width - $sidebar-width);
  }
}
.is-noUserSelect {
  user-select: none;
}
.main-content {
  overflow: auto;
  background-color: $c-body;
  padding: 0 20px;
  padding-bottom: 1.5em;
  height: calc(100vh - 60px);
}
.main-content-routes {
  padding: 0 0 running($pad-med) 0;
}

@media (min-width: 800px) {
  .container-wrap {
    display: inherit;
  }
}
